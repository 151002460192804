import { Button } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import AdminAuth from "../../utility/auth";
export const COLUMNS = [
  {
    Header: "Agent Name",
    Cell: (props) => <p>{props.row.original.name}</p>, //RDT provides index by default
  },
  {
    Header: "Agent Code",
    Cell: (props) => {
      return props.row.original.agentCode;
    },
    Filter: () => {
      return null;
    },
  },
  {
    Header: "Agent Email",
    accessor: "createdAt",
    Cell: (props) => {
      return props.row.original?.email ?? "none";
    },
  },
  {
    Header: "Phone Number",
    Cell: (props) => {
      return props.row.original?.phnNo ?? "none";
    },
  },
  {
    Header: "Type",
    Cell: (props) => {
      return props.row.original?.agentType ?? "none";
    },
  },
  {
    Header: "DATE CREATED",
    // accessor: "createdAt",
    Cell: (props) => {
      return moment(props.row.original.createdAt).format("DD-MM-YYYY hh:mm A");
    },
  },
  {
    Header: "Action",
    // accessor: "createdAt",
    Cell: (props) => {
      return (
        <div>
          <Button
            onClick={() => {
              AdminAuth.getInstance().eventEmitter.emit(
                "showEdit",
                props.row.original
              );
            }}
            variant="contained"
          >
            edit
          </Button>
          {" "}
          <Button
            onClick={() => {
              AdminAuth.getInstance().eventEmitter.emit(
                "showDelete",
                props.row.original
              );
            }}
            variant="outlined"
            color="error"
          >
            Delete
          </Button>
        </div>
      );
    },
  },
];
