import { CircularProgress } from "@material-ui/core";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { getQrDetailByFullId } from "../api";
import QrDetail from "./QrDetails";
import QrDetailsForFullId from "./QrDetailsForFullId";

const QrDataByDbId = (props: any) => {
  const [qrid, setQrId] = useState("");
  const [open, setOpen] = useState(false);
  const [qrdata, setQrData] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!loading) {
      setLoading(true);
      const res = await getQrDetailByFullId(qrid);
      if (res.isSuccess) {
        toast.success("Success");
        if (res.data.isActivated) {
          setOpen(true);
          setQrData(res.data);
        } else {
          toast.error("This QR code is not activated"!);
        }
      } else {
        toast.error("Invalid QR Id");
      }
      setLoading(false);
      setQrId("");
    } else {
      return;
    }
  };
  return (
    <div
      className="auth-container"
      style={{
        backgroundImage: "linear-gradient(to left, #fff,#fff)",
      }}
    >
      <form className="form" onSubmit={handleSubmit}>
        <p className="form-title">QR Detail</p>
        <input
          placeholder="Enter QR Full ID"
          onChange={(e) => {
            setQrId(e.target.value);
          }}
          type={"text"}
          value={qrid}
          required
        />
        <button>
          {loading ? <CircularProgress size={17} color="inherit" /> : "Submit"}
        </button>
      </form>
      {qrdata && !loading && (
        <QrDetailsForFullId qrdata={qrdata} open={open} setOpen={setOpen} />
      )}
    </div>
  );
};

export default QrDataByDbId;
