import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import moment from "moment";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function QrDetailsForFullId(props: any) {
  const handleClickOpen = () => {
    props.setOpen(true);
  };

  const handleClose = () => {
    props.setOpen(false);
  };
  console.log("row", props?.row);
  console.log(props,"pppppp")
  // if(true){
  //   return <h3
  //   style={{
  //     margin: "10px 0",
  //   }}
  // >
  //   {`QR code(${props.qrdata.shortId}) Data`}
  // </h3>
  // }
  return (  
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div
          style={{
            padding: 10,
          }}
        >
          <h3
            style={{
              margin: "10px 0",
            }}
          >
            {`QR code(${props?.qrdata?.shortId}) Data`}
          </h3>
        </div>
        <div
          style={{
            padding: 10,
            fontWeight: 500,
          }}
        >
          <p>
            Created At :{" "}
            {moment(props.qrdata.createdAt).format("YYYY-MM-DD/hh:mm:ss A")}
          </p>
        </div>
        <div
          style={{
            padding: 10,
            fontWeight: 500,
          }}
        >
          <p>
            Short ID :{" "}
            {props?.qrdata?.shortId}
          </p>
        </div>
        {/* <div
          style={{
            padding: 10,
            fontWeight: 500,
          }}
        >
          <h3>Address</h3>
          <div
            style={{
              padding: "5px 0",
            }}
          >
            <p>
              Home Type : {props.qrdata?.homeUsers[0]?.home?.homeType ?? "--"}
            </p>
            <p>
              Home Title : {props.qrdata?.homeUsers[0]?.home?.homeTitle ?? "--"}
            </p>
            <p>
              Full Address :{" "}
              {props.qrdata?.homeUsers[0]?.home?.fullAddress ?? "--"}
            </p>
          </div>
        </div> */}
        <div
          style={{
            padding: 10,
            fontWeight: 500,
          }}
        >
          {/* <h3
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div className="member-round">
              <span>{props.qrdata.homeUsers.length}</span>
            </div>
            {" "}Members
          </h3>
          {props.qrdata.homeUsers.map((homeUser: any, i: number) => {
            return (
              <div
                style={{
                  padding: "5px 0",
                }}
              >
                <p>Name : {homeUser.user.name}</p>
                <p>
                  Phone Number/email :{" "}
                  {homeUser.user.phoneNumber ??
                    homeUser.user.email ??
                    homeUser.user.phoneNo}
                </p>
              </div>
            );
          })} */}
          <div style={{marginTop:"10px"}}>
          <h3>Subscription Details</h3>
          <div>
            {/* <p>Subscription Name : {props.qrdata?.currentSubscriptionPlan?.subscriptionUniqueName??"-"}</p> */}
            <p>isActive : {props.qrdata?.currentSubscriptionPlan?.isActive?"Activated":"Inactive"}</p>
            {/* <p>isExpired : {props.qrdata?.currentSubscriptionPlan?.isExpired?"Expired":"Not"}</p> */}
            {/* <p>Active On : {props.qrdata?.currentSubscriptionPlan?.subscriptionActivatedOn?moment(props.qrdata?.currentSubscriptionPlan?.subscriptionActivatedOn).format("YYYY-MM-DD/hh:mm:ss A"):"--"}</p> */}
            {/* <p>Expire On : {props.qrdata?.currentSubscriptionPlan?.subscriptionExpireOn?moment(props.qrdata?.currentSubscriptionPlan?.subscriptionExpireOn).format("YYYY-MM-DD/hh:mm:ss A"):"--"}</p> */}
          </div>
          </div>
        </div>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
