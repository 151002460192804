import { Component } from "react";
import { Button, CircularProgress } from "@mui/material";
import "../styles/checkServerStatus.css";
import wrongImg from "../assets/img/wrong.png";
import correctImg from "../assets/img/correct.png";
import WebrtcCallHandler from "../utility/WebrtcCallHandler";
import { VaniEvent } from "vani-meeting-client";
import AdminAuth from "../utility/auth";

interface Props {}
interface State {
  statusLoading: boolean;
  newarkServerStatus: string;
  bangaloreStatus: string;
  frankFartStatus: string;
  currentServer: string;
  apiServerStatus: string;
}
enum serverStatus {
  loading = "loading",
  success = "success",
  failure = "failure",
  tested = "tested",
  readyToTest = "readyToTest",
}
enum currentServer {
  India = "India",
  NewYork = "NewYork",
  Frank = "Frank",
}
export class CheckServerStatus extends Component<Props, State> {
  frankTimeOut: any;
  newYorkTimeOut: any;
  bangApi: any;
  bangServer: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      statusLoading: false,
      newarkServerStatus: serverStatus.readyToTest,
      bangaloreStatus: serverStatus.readyToTest,
      frankFartStatus: serverStatus.readyToTest,
      currentServer: currentServer.India,
      apiServerStatus: serverStatus.readyToTest,
    };
  }
  componentDidMount(): void {
    console.log("componentDidMount onServerStatusCheckButtonPressed")
    this.onServerStatusCheckButtonPressed();
    clearTimeout(this.bangServer);
    clearTimeout(this.newYorkTimeOut);
    clearTimeout(this.frankTimeOut);
  }
  onServerStatusCheckButtonPressed = () => {
    WebrtcCallHandler.getInstance().cleanUp(false);
    clearTimeout(this.bangServer);
    clearTimeout(this.newYorkTimeOut);
    clearTimeout(this.frankTimeOut);
    if (!this.state.statusLoading) {
      this.setState({
        newarkServerStatus: serverStatus.readyToTest,
        bangaloreStatus: serverStatus.readyToTest,
        frankFartStatus: serverStatus.readyToTest,
        apiServerStatus: serverStatus.readyToTest,
        currentServer: currentServer.India,
      });
      this.setState({ statusLoading: true });
      this.onCallSetup("wss://ind.socket.doorvi.co:4031/?connection=");
      this.bangServer = setTimeout(() => {
        if (this.state.bangaloreStatus === serverStatus.readyToTest) {
          console.log(
            "server fail...........................................",
            this.state.currentServer
          );
          WebrtcCallHandler.getInstance().cleanUp(false);
          this.setState({
            bangaloreStatus: serverStatus.failure,
            currentServer: currentServer.NewYork,
          });
          setTimeout(() => {
            this.checkNewYorkServer("wss://us.socket.doorvi.co:4031");
          }, 2000);
        }
      }, 5000);
    }
  };
  onCallSetup = (wss: string) => {
    WebrtcCallHandler.getInstance().setup(
      "1234",
      new Date().getTime() + "_" + Math.floor(1000 + Math.random() * 9000),
      wss
    );
    WebrtcCallHandler.getInstance()
      .getMeetingHandler()
      .getEventEmitter()
      ?.on(VaniEvent.OnInitDone, this.onInitDone);
    WebrtcCallHandler.getInstance().getMeetingHandler().init();
  };
  onInitDone = () => {
    WebrtcCallHandler.getInstance()
      .getMeetingHandler()
      .getEventEmitter()
      ?.off(VaniEvent.OnInitDone, this.onInitDone);
    WebrtcCallHandler.getInstance()
      .getMeetingHandler()
      .getEventEmitter()
      ?.on(VaniEvent.OnSocketConnected, this.onSocketConnected);
    WebrtcCallHandler.getInstance().getMeetingHandler().checkSocket();
  };
  onSocketConnected = () => {
    clearTimeout(this.bangServer);
    console.log(
      "server working...........................................",
      this.state.currentServer
    );
    if (this.state.currentServer === currentServer.India) {
      console.log(
        "server inside india...........................................",
        this.state.currentServer
      );
      this.setState({
        bangaloreStatus: serverStatus.success,
        currentServer: currentServer.NewYork,
      });
      WebrtcCallHandler.getInstance()
        .getMeetingHandler()
        .getEventEmitter()
        ?.off(VaniEvent.OnSocketConnected, this.onSocketConnected);
      WebrtcCallHandler.getInstance().cleanUp(false);
      setTimeout(() => {
        this.checkNewYorkServer("wss://us.socket.doorvi.co:4031");
      }, 2000);
    } else if (this.state.currentServer === currentServer.NewYork) {
      clearTimeout(this.newYorkTimeOut);
      console.log(
        "server inside newyork...........................................",
        this.state.currentServer
      );
      this.setState({
        newarkServerStatus: serverStatus.success,
        currentServer: currentServer.Frank,
      });
      WebrtcCallHandler.getInstance()
        .getMeetingHandler()
        .getEventEmitter()
        ?.off(VaniEvent.OnSocketConnected, this.onSocketConnected);
      WebrtcCallHandler.getInstance().cleanUp(false);
      setTimeout(() => {
        this.checkFrankServer("wss://eu.socket.doorvi.co:4031");
      }, 2000);
    } else if (this.state.currentServer === currentServer.Frank) {
      clearTimeout(this.frankTimeOut);
      console.log(
        "server inside frank...........................................",
        this.state.currentServer
      );
      this.setState({
        frankFartStatus: serverStatus.success,
      });
      WebrtcCallHandler.getInstance()
        .getMeetingHandler()
        .getEventEmitter()
        ?.off(VaniEvent.OnSocketConnected, this.onSocketConnected);
      WebrtcCallHandler.getInstance().cleanUp(false);
      this.checkApiServerStatus();
    }
  };
  checkFrankServer = (wss: string) => {
    this.onCallSetup(wss);
    this.frankTimeOut = setTimeout(() => {
      if (this.state.frankFartStatus === serverStatus.readyToTest) {
        console.log(
          "server fail...........................................",
          this.state.currentServer
        );
        WebrtcCallHandler.getInstance().cleanUp(false);
        this.setState({ frankFartStatus: serverStatus.failure });
        this.checkApiServerStatus();
      }
    }, 5000);
  };
  checkNewYorkServer = (wss: string) => {
    this.onCallSetup(wss);
    this.newYorkTimeOut = setTimeout(() => {
      if (this.state.newarkServerStatus === serverStatus.readyToTest) {
        console.log(
          "server fail...........................................",
          this.state.currentServer
        );
        WebrtcCallHandler.getInstance().cleanUp(false);
        this.setState({
          currentServer: currentServer.Frank,
          newarkServerStatus: serverStatus.failure,
        });
        setTimeout(() => {
          this.onCallSetup("wss://eu.socket.doorvi.co:4031");
        }, 2000);
      }
    }, 5000);
  };
  checkApiServerStatus = async () => {
    clearTimeout(this.frankTimeOut);
    if (AdminAuth.getInstance().isLoggedIn) {
      this.setState({ apiServerStatus: serverStatus.success });
    } else {
      this.setState({ apiServerStatus: serverStatus.failure });
    }
    this.setState({ statusLoading: false });
  };
  render() {
    const {
      statusLoading,
      newarkServerStatus,
      bangaloreStatus,
      apiServerStatus,
    } = this.state;
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: "30px",
        }}
      >
        <div className="server_checkup">
          <p className="server_pera">Bangalore Server Status</p>
          {statusLoading && bangaloreStatus === serverStatus.readyToTest ? (
            <CircularProgress
              style={{ color: "black", height: "23px", width: "23px" }}
            />
          ) : (
            ""
          )}
          {bangaloreStatus === serverStatus.success ? (
            <img src={correctImg} style={{ height: "30px" }} />
          ) : (
            ""
          )}
          {bangaloreStatus === serverStatus.failure ? (
            <img src={wrongImg} style={{ height: "30px" }} />
          ) : (
            ""
          )}
        </div>{" "}
        <div className="server_checkup">
          <p className="server_pera">New York Server Status</p>
          {statusLoading && newarkServerStatus === serverStatus.readyToTest ? (
            <CircularProgress
              style={{ color: "black", height: "23px", width: "23px" }}
            />
          ) : (
            ""
          )}
          {newarkServerStatus === serverStatus.success ? (
            <img src={correctImg} style={{ height: "30px" }} />
          ) : (
            ""
          )}
          {newarkServerStatus === serverStatus.failure ? (
            <img src={wrongImg} style={{ height: "30px" }} />
          ) : (
            ""
          )}
        </div>
        <div className="server_checkup">
          <p className="server_pera">Frankfurt Server Status</p>
          {statusLoading &&
          this.state.frankFartStatus === serverStatus.readyToTest ? (
            <CircularProgress
              style={{ color: "black", height: "23px", width: "23px" }}
            />
          ) : (
            ""
          )}
          {this.state.frankFartStatus === serverStatus.success ? (
            <img src={correctImg} style={{ height: "30px" }} />
          ) : (
            ""
          )}
          {this.state.frankFartStatus === serverStatus.failure ? (
            <img src={wrongImg} style={{ height: "30px" }} />
          ) : (
            ""
          )}
        </div>
        <div className="server_checkup">
          <p className="server_pera">API Server Status</p>
          {statusLoading && apiServerStatus === serverStatus.readyToTest ? (
            <CircularProgress
              style={{ color: "black", height: "23px", width: "23px" }}
            />
          ) : (
            ""
          )}
          {apiServerStatus === serverStatus.success ? (
            <img src={correctImg} style={{ height: "30px" }} />
          ) : (
            ""
          )}
          {apiServerStatus === serverStatus.failure ? (
            <img src={wrongImg} style={{ height: "30px" }} />
          ) : (
            ""
          )}
        </div>
        <Button
          variant="contained"
          color="success"
          style={{
            marginTop: "50px",
            height: "50px",
            fontSize: "17px",
            width: "300px",
            textTransform: "none",
          }}
          onClick={this.onServerStatusCheckButtonPressed}
          //   onClick={this.checkApiServerStatus}
        >
          {!statusLoading ? (
            "Check Server Status"
          ) : (
            <CircularProgress
              style={{ color: "white", height: "23px", width: "23px" }}
            />
          )}
        </Button>
      </div>
    );
  }
}

export default CheckServerStatus;
