import { CircularProgress, InputLabel, MenuItem, OutlinedInput, Select } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { changeSubscriptionByQrApi, getAllPlans } from "../api";
import { FormControl } from "@mui/material";

const ChangeSubscription = (props: any) => {
  const [qrid, setQrId] = useState("");
  const [months, setMonths] = useState(-1);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [plans, setPlans] = useState([{ subscriptionValidTillInMonth: -1 }]);
  const [selectedPlan, setSelectedPlan] = useState({ name: "mm", subscriptionUniqueName: "" });
  const handleChange = (value: any) => {
    console.log(value);
    setSelectedPlan(value.target.value);
  }
  useEffect(() => {
    getPlans();
  }, [])
  const planvalidaityInMonth = [
    { label: "One month", value: 1 },
    { label: "3 month", value: 3 },
    { label: "6 month", value: 6 },
    { label: "9 month", value: 9 },
    { label: "12 month", value: 12 },
    { label: "lifetime", value: -1 },
  ];
  const getPlans = async () => {
    const plansRes = await getAllPlans();
    console.log(plansRes);
    setPlans(plansRes.data);
    setPageLoading(false)
    setSelectedPlan(plansRes.data[0])
  }
  const changeSubscriptionByQr = async () => {
    setLoading(true);
    console.log(selectedPlan, qrid, months);
    if (!qrid || !selectedPlan.name) {
      toast.error("All Fields Are Mandatory!")
    }
    else {
      const res = await changeSubscriptionByQrApi({
        shortId: qrid, subscriptionPlans: [{ subscriptionUniqueName: selectedPlan.subscriptionUniqueName, subscriptionValidTillInMonth: months }, { subscriptionUniqueName: "com.doorvi.basic", subscriptionValidTillInMonth: -1 }]
      });
      console.log(res);
      if (res.data) {
        toast.success("Plan Updated Successfully")
        setSelectedPlan(plans[0] as any);
        setMonths(-1);
        setQrId("")
      }
      else {
        toast.error(res.message);
      }
    }
    setLoading(false);
  }
  if (pageLoading) {
    return <p>Loading....</p>
  }
  return (

    <div
      className="auth-container"
      style={{
        backgroundImage: "linear-gradient(to left, #fff,#fff)",
      }}
    >
      <div className="form">
        <p className="form-title">Change Qr Plan</p>
        <FormControl sx={{ m: 1, width: "90%" }}>
          <InputLabel id="demo-multiple-name-label" style={{ marginBottom: "4px", paddingLeft: "3px", fontWeight: "600" }}>Select New Plan</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            // multiple
            value={selectedPlan}
            onChange={handleChange}
            input={<OutlinedInput label="Name" />}
            style={{
              height: "45px",
              border: "2px solid gray",
              borderRadius: "10px",
            }}
          // MenuProps={MenuProps}
          >
            {plans.map((plan: any) => (
              <MenuItem
                key={plan.name}
                value={plan}
              // style={getStyles(plan, personplan, theme)}
              >
                {plan.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className="w-full" style={{ width: "90%" }}>
          <InputLabel id="demo-multiple-name-label" style={{ paddingLeft: "3px", fontWeight: "600" }}>Enter Qr Id</InputLabel>
        </div>
        <input
          placeholder="Enter QR ID"
          onChange={(e) => {
            setQrId(e.target.value);
          }}
          type={"text"}
          value={qrid}
          required
        />        
        <FormControl sx={{ m: 1, width: "90%" }}>
          <InputLabel id="demo-multiple-name-label" style={{ marginBottom: "4px", paddingLeft: "3px", fontWeight: "600" }}>Select validity</InputLabel>
          <Select
            labelId="demo-multiple-name-label"
            id="demo-multiple-name"
            value={months}
            onChange={(e) => {
              // setPlanvalidaity(e.target.value);
              setMonths(e.target.value as number);
            }}
            input={<OutlinedInput label="Name" />}
            style={{
              height: "45px",
              border: "2px solid gray",
              borderRadius: "10px",
            }}
          >
            {planvalidaityInMonth.map((plan: any) => (
              <MenuItem value={plan.value}>{plan.label}</MenuItem>
            ))}
          </Select>
        </FormControl>        
        <button onClick={changeSubscriptionByQr}>
          {loading ? <CircularProgress size={17} color="inherit" /> : "Submit"}
        </button>

      </div>
    </div>
  );
};

export default ChangeSubscription;
