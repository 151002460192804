import React, { useMemo } from "react";
import { QRCodeSVG } from "qrcode.react";
import firstQr from "./qrs/14.png";
import Qr2 from "./qrs/15.png";
import { QRCode } from "react-qrcode-logo";

import "./index.css";
import { getCountryCallingCode } from "libphonenumber-js";

const CustomQr = (props: any) => {
  const images = useMemo(
    () => [
      require("./qrs/14.png"),
      require("./qrs/15.png"),
      require("./qrs/17.png"),
      require("./qrs/18.png"),
      require("./qrs/19.png"),
      require("./qrs/21.png"),
      require("./qrs/22.png"),
    ],
    []
  );
  return (
    <div className="customqr_wrap">
      <img src={props.img ?? images[0]} />
      <QRCodeSVG
        size={75}
        colorProfile={"#4C4C4C"}
        color="#4C4C4C"
        className="qrcode"
        value={props.value ?? "https://admin.doorvi.co/custom-qr"}
        imageSettings={{
          src: "/doorvi.svg",
          excavate: false,
          height: 17,
          width: 37,
        }}
        level="H"
        fgColor="#4C4C4C"
      />
      <p className="customqr-id">
        {props.countries[0] === "all"
          ? `No 0-${props.qr.shortId}`
          : `No ${getCountryCallingCode(props.countries[0])}-${
              props.countries.length
            }${props.qr.shortId}`}
      </p>
    </div>
  );
};

export default CustomQr;
