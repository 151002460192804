import { CircularProgress } from "@material-ui/core";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { AddAgentApi, getQrDetail } from "../api";
import QrDetail from "../components/QrDetails";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const AddAgent = (props: any) => {
  const agents = [
    { label: "Admin", value: "Admin" },
    { label: "Agent", value: "Agent" },
  ];
  const [agentName, setAgentName] = useState("");
  const [agentEmail, setAgentEmail] = useState("");
  const [agentPhoneno, setAgentPhoneno] = useState("");
  const [agentAddress, setAgentAddress] = useState("");
  const [agentCode, setAgentCode] = useState("");
  const [agentPass, setAgentPass] = useState("");
  const [agentType, setAgentType] = useState("Agent");
  const [open, setOpen] = useState(false);
  const [qrdata, setQrData] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!loading) {
      setLoading(true);
      const res = await AddAgentApi(
        agentName,
        agentCode,
        agentEmail,
        agentPass,
        agentAddress,
        agentPhoneno,
        agentType
      );
      if (res && res.isSuccess) {
        toast.success("Agent added");
      } else {
        toast.error("Something went wrong");
      }
      setLoading(false);
      setAgentName("");
      setAgentCode("");
      setAgentAddress("");
      setAgentEmail("");
      setAgentPass("");
      setAgentPhoneno("");
      setAgentType("Agent");
    } else {
      return;
    }
  };
  return (
    <div
      className="auth-container"
      style={{
        backgroundImage: "linear-gradient(to left, #fff,#fff)",
      }}
    >
      <form className="form" onSubmit={handleSubmit}>
        <p className="form-title">QR Detail</p>
        <input
          placeholder="Enter Agent Name"
          onChange={(e) => {
            setAgentName(e.target.value);
          }}
          type={"text"}
          value={agentName}
          required
        />
        <input
          placeholder="Enter Agent Code"
          onChange={(e) => {
            setAgentCode(e.target.value);
          }}
          type={"text"}
          value={agentCode}
          required
        />
        <input
          placeholder="Enter Agent Email"
          onChange={(e) => {
            setAgentEmail(e.target.value);
          }}
          type={"text"}
          value={agentEmail}
          required
        />
        <input
          placeholder="Enter Agent Phone Number"
          onChange={(e) => {
            setAgentPhoneno(e.target.value);
          }}
          type={"text"}
          value={agentPhoneno}
          required
        />
        <input
          placeholder="Enter Agent Address"
          onChange={(e) => {
            setAgentAddress(e.target.value);
          }}
          type={"text"}
          value={agentAddress}
          required
        />
        <input
          placeholder="Enter Agent Password"
          onChange={(e) => {
            setAgentPass(e.target.value);
          }}
          type={"text"}
          value={agentPass}
          required
        />
        <FormControl
          style={{
            width: "90%",
            fontFamily: "inherit",
            margin: "15px 0",
          }}
        >
          <InputLabel
            style={{
              fontFamily: "inherit",
            }}
            id="demo-simple-select-label"
          >
            Select Agent Type
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={agentType}
            label="Select Depot"
            onChange={(e) => {
              setAgentType(e.target.value);
            }}
            style={{
              fontFamily: "inherit",
              borderRadius: 10,
            }}
          >
            {agents.map((agent: any) => (
              <MenuItem value={agent.value}>{agent.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <button>
          {loading ? <CircularProgress size={17} color="inherit" /> : "Submit"}
        </button>
      </form>
      {qrdata && !loading && (
        <QrDetail qrdata={qrdata} open={open} setOpen={setOpen} />
      )}
    </div>
  );
};

export default AddAgent;
