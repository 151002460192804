import React, { Component } from "react";
import { QRCodeSVG } from "qrcode.react";
import { ReactComponent as DoorViLogo } from "../../assets/img/doorvi.svg";
import { ReactComponent as BorderBottom } from "../../assets/img/border_bottom.svg";
import { ReactComponent as Ring } from "../../assets/img/call.svg";
import btdc from "../../assets/img/btdc.png";
import { useTranslation } from "react-i18next";
import { getCountryCallingCode } from "libphonenumber-js";
interface QRGenratorProps {
  value: string;
  index: number;
  qr: any;
  qrType: string;
}

function BTDCQRGenrator(props: any) {
  const { t } = useTranslation();
  return (
    <>
      <div
        className="qr-wrap"
        style={{
          paddingBottom: 15,
        }}
      >
        <p className="qr-id">
          {props.countries[0] === "all"
            ? `No 0-${props.qr.shortId}`
            : `No ${getCountryCallingCode(props.countries[0])}-${
                props.countries.length
              }${props.qr.shortId}`}
        </p>
        <p className="qr-val">
          <Ring
            style={{
              width: 22,
              height: 20,
            }}
          />
        </p>

        <QRCodeSVG
          size={182}
          style={{
            margin: "5px 0",
          }}
          className="qrcode"
          value={props.value}
        />
        <div className="qr-footer">
          <p>{t("Scan_QR_Code")}</p>
          <p>{t("To_Contact_inside")}</p>
          <BorderBottom
            style={{
              marginTop: 3,
            }}
          />
        </div>
        <ul
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            margin: "10px 0",
          }}
        >
          <img src={btdc} style={{ width: 60, height: 35, marginLeft: 25 }} />
          <DoorViLogo style={{ width: 110, height: 35 }} />
        </ul>
        <ul className="btdcqr-footer">
          <li>Call For CCTV 9425819081</li>
          <li>Complete Security Solutions</li>
        </ul>
        {/* <div className="footer-logo_wrap"> */}
      </div>
    </>
  );
}

export default BTDCQRGenrator;
