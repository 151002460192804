import { combineReducers } from "redux";

const globalReducer = (state = { dateRange: null,status:null }, action) => {
  if (action.type === "SET_DATE_RANGE") {
    return { ...state, dateRange: action.payload };
  }
  else if (action.type === "SET_STATUS_FILTERVALUE") {
    return { ...state, status: action.payload };
  } else {
    return state;
  }
};

export default combineReducers({
  global: globalReducer,
});
