
import { QRCodeSVG } from "qrcode.react";
import bell from "../../assets/img/sticker_2.svg";
import { getCountryCallingCode } from "libphonenumber-js";
import logo from "../../assets/DoorVi™ Logo-03.jpg"
import qrLogo from "../../assets/DoorVi™ Logo-06.jpg"

const BlackDoorvi = (props: any) => {
  return (
    <div style={{background:"#000",padding:'40px 29px 130px 29px',display:"flex",flexDirection:'column',gap:"30px",position:"relative",borderRadius:"20px"}}>
      
      <QRCodeSVG
          size={158}
          style={{
            padding: 10,
            background:"#fff",
            top: 0,
            // position:"relative"
          }}
          level="L"
          // imageSettings={{
          //   src: qrLogo,
          //   height: 35,
          //   width: 35,
          //   excavate: true,
          // }}
          className="qrcode-bell"
          value={
            props.value ??
            "https://call.doorvi.co/details?vm=659549c6d46adbd47ca0e89a"
          }
        />
        <p className="qr-id-design_black">
        {props.countries[0] === "all"
          ? `${props.qr.shortId}`
          : `${getCountryCallingCode(props.countries[0])}-${
              props.countries.length
            }-${props.qr.shortId}`}
      </p>
        
        <div style={{color:"#fff",fontSize:'28px',fontWeight:500}}>
          <p>SCAN QR CODE</p>
          <p>TO RING DOORBELL</p>
        </div>
        <img src={logo} style={{width:"180px",position:"absolute",bottom:"10px",left:"4px"}}/>      
    </div>
  );
};

export default BlackDoorvi;
