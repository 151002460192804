import React, { Component, createRef, useEffect, useState } from "react";
import { IoReload, IoSearchSharp } from "react-icons/io5";
import { FiFilter } from "react-icons/fi";
import {
  InputLabel,
  Button,
  MenuItem,
  FormControl,
  Select,
} from "@material-ui/core";
import DatePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import { CSVLink, CSVDownload } from "react-csv";
import baseUrl from "../../api/baseUrl";
import CsvDownload from "react-csv-downloader";
import AdminAuth from "../../utility/auth";

class TableHeaderAction extends Component {
  // const [csvDataState, setcsvDataState] = useState([{}])
  constructor() {
    super();
    this.state = {
      csvDataState: [],
      agentData: [],
      agent: undefined,
    };
    this.csvInstance = createRef(null);
  }
  onDownload = async () => {
    /* global BigInt */
    let entries = prompt("Enter no of entries to download");
    const data_to_download = [];
    const res = await baseUrl.post("/getAllUsers", {
      pageNo: 0,
      noOfQRCode: this.props.twoHomeApi ? 2 : -1,
      limit: parseInt(entries),
      startDateTimestamp: !this.props.dateRange
        ? -1
        : new Date(this.props.dateRange[0]).getTime(),
      endDateTimestamp: !this.props.dateRange
        ? -1
        : new Date(this.props.dateRange[1]).getTime(),
      agentId:
        AdminAuth.getInstance().userData?.agentType === "Admin" &&
        this.props.agentType === "Admin"
          ? undefined
          : this.props.agentType,
    });
    if (res.data && res.data.data && res.data.data.users.length > 0) {
      const csvData = res.data.data.users;
      console.log("csvData", csvData);
      csvData.forEach((data) => {
        const isActivated = [];
        const productType = [];
        data.homes.forEach((home) => {
          home.qrCodes.forEach((qr) => {
            return isActivated.push(qr.isActivated);
          });
        });
        data.homes.forEach((home) => {
          home.qrCodes.forEach((qr) => {
            return productType.push(qr.type);
          });
        });
        // console.log("isActivated", isActivated);
        data_to_download.push({
          name: data.name,
          "phoneNumber/email": data.email ?? BigInt(data.phoneNumber),
          QRCode: String(isActivated),
          isLoggedIn: data.isLoggedIn,
          productType: String(productType),
          createdAt: data.createdAt,
        });
      });
    }
    console.log("data_to_download", data_to_download);
    return Promise.resolve(data_to_download);
    // if (data_to_download.length) {
    //   this.setState({ csvDataState: data_to_download }, () => {
    //     // done(true);
    //     this.setState({ csvDataState: [] });
    //   });
    // }
  };
  dataFromListOfUsersState = () => {
    return this.state.csvDataState;
  };
  fetchAgentData = async () => {
    try {
      const res = await baseUrl.get(`/listAgents`);
      if (res.data.isSuccess) {
        this.setState({
          agentData: [...res.data.data],
        });
      }
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  componentDidMount() {
    // this.fetchAgentData();
  }
  render() {
    console.log("this.state.csvDataState", this.state.csvDataState);
    const columns = [
      {
        id: "name",
        displayName: "Name",
      },
      {
        id: "phoneNumber/email",
        displayName: "phoneNumber/email",
      },
      {
        id: "QRCode",
        displayName: "QRCode",
      },
      {
        id: "isLoggedIn",
        displayName: "isLoggedIn",
      },
      {
        id: "productType",
        displayName: "productType",
      },
      {
        id: "createdAt",
        displayName: "createdAt",
      },
    ];

    return (
      <>
        <div className="table__header-action">
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="table__form-box">
              <IoSearchSharp size={22} color="#000" />
              <input
                value={this.props.searchText || ""}
                onChange={(e) => {
                  this.props.setSearchText(e.target.value);
                  this.props.setPagination({ pageIndex: 0, pageSize: 10 });
                }}
                type="text"
                placeholder="Search by name,phone.."
              />
            </div>
          </form>

          <div className="table__header-icons">
            <IoReload onClick={this.props.reset} size={25} cursor="pointer" />
          </div>
        </div>
      </>
    );
  }
}

export default TableHeaderAction;
