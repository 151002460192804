import React, { useEffect, useRef, useState } from "react";
import {
  useTable,
  useSortBy,
  useFilters,
  usePagination,
  useGlobalFilter,
} from "react-table";
import { COLUMNS } from "./Column";
import "../styles/table.css";
import TableHeaderAction from "./TableHeaderAction";
import { IoArrowUp, IoArrowDown } from "react-icons/io5";
import { FiChevronUp } from "react-icons/fi";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
  makeStyles,
} from "@material-ui/core";
import { connect, useSelector } from "react-redux";
import { setDateRange, setStatusFilterValue } from "../actions";
import OrderDetail from "../components/Orderdetails";
import { Button } from "@mui/material";
// import OrderModal from "../OrderDescription";

const OrderTable = ({
  setDateRange,
  setStatusFilterValue,
  orders,
  fetchOrdersData,
}) => {
  const Reduxstate = useSelector((state) => state.global);
  const topRef = useRef();
  const [goTop, setGoTop] = useState(false);
  const [open, setOpen] = useState(false);
  const [clickedRow, setClickedRow] = useState(null);
  // console.log(Reduxstate);
  const useStyles = makeStyles({
    container: {
      minHeight: 250,
      maxHeight: 650,
    },
  });

  const classes = useStyles();
  console.log("clickedRow", clickedRow);
  const renderToggleIcon = (column) => {
    if (column.isSorted) {
      if (column.isSortedDesc) {
        return (
          <IoArrowDown
            color="#fff"
            onClick={() => column.toggleSortBy(false)}
          />
        );
      } else {
        return (
          <IoArrowUp color="#fff" onClick={() => column.toggleSortBy(true)} />
        );
      }
    } else {
      return;
    }
  };
  const {
    getTableProps,
    page,
    rows,
    state,
    setGlobalFilter,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setFilter,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
  } = useTable(
    {
      columns: COLUMNS,
      data: orders,
      initialState: {
        sortBy: [
          {
            id: "createdAt",
            desc: true,
          },
        ],
        pageSize: 10,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex } = state;

  const createRowsFromFilter = (row) => {
    if (Reduxstate.dateRange) {
      if (
        new Date(String(Reduxstate.dateRange[0])) <=
          new Date(row.cells[3].value) &&
        new Date(Reduxstate.dateRange[1]) >= new Date(row.cells[3].value)
      ) {
        prepareRow(row);
        return (
          <TableRow
            hover
            onClick={() => {
              setOpen(true);
              setClickedRow(row);
            }}
            {...row.getRowProps()}
          >
            {row.cells.map((cell) => {
              return (
                <TableCell {...cell.getCellProps()}>
                  {cell.render("Cell")}
                </TableCell>
              );
            })}
          </TableRow>
        );
      } else {
        return null;
      }
    } else {
      prepareRow(row);
      return (
        <TableRow
          hover
          onClick={() => {
            setOpen(true);
            setClickedRow(row);
          }}
          {...row.getRowProps()}
        >
          {row.cells.map((cell) => {
            return (
              <TableCell {...cell.getCellProps()}>
                {cell.render("Cell")}
              </TableCell>
            );
          })}
        </TableRow>
      );
    }
  };

  const handleGoToTop = () => {
    topRef.current.scrollIntoView({ behaviour: "smooth" });
  };
  console.log(clickedRow);
  return (
    <div className="table__wrap">
      <div className="table__header-action-container">
        <TableHeaderAction
          filter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          setFilter={setFilter}
          setDateRangeFilter={setDateRange}
          setStatusFilterValue={setStatusFilterValue}
          fetchOrdersData={fetchOrdersData}
        />
      </div>
      {orders[0] && (
        <TableContainer
          onScroll={(e) => {
            e.target.scrollTop >= e.target.clientHeight
              ? setGoTop(true)
              : setGoTop(false);
          }}
          className={classes.container}
        >
          <div className="top" ref={topRef}></div>
          <Table stickyHeader className="table" {...getTableProps()}>
            <TableHead>
              {headerGroups.map((headerGroup) => {
                return (
                  <TableRow
                    className="table__header-row"
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column) => {
                      return (
                        <TableCell
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}
                          <span>{renderToggleIcon(column)}</span>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {page.map((row) => {
                return createRowsFromFilter(row);
              })}
            </TableBody>
            <div
              onClick={handleGoToTop}
              className={`top__icon ${goTop ? "" : "hide"}`}
            >
              <FiChevronUp size={30} color="#fff" />
            </div>
          </Table>
        </TableContainer>
      )}
      {/* modal */}
      {orders[0] && clickedRow && (
        <OrderDetail row={clickedRow} open={open} setOpen={setOpen} />
      )}{" "}
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-around",
          alignItems: "center",
          margin: 0,
        }}
      >
        <Button
          onClick={previousPage}
          disabled={!canPreviousPage}
          variant="outlined"
        >
          Prev
        </Button>
        <div>
          <p>
            {pageIndex + 1} of {pageOptions.length}
          </p>
        </div>
          <Button
            style={{
              fontWeight: "600",
            }}
            onClick={nextPage}
            disabled={!canNextPage}
            variant="outlined"
          >
            Next
          </Button>
      </div>
    </div>
  );
};

export default connect(null, { setDateRange, setStatusFilterValue })(
  OrderTable
);
