import { QRCodeSVG } from "qrcode.react";
import bell from "../../assets/img/long_sticker_3.svg";
import { getCountryCallingCode } from "libphonenumber-js";

const QR_NEWBell_REC = (props: any) => {
  return (
    <div className="qr-wrap-desing_2" style={{}}>
      <p className="qr-id-design_2" style={{color:"#fff"}}>
        {props.countries[0] === "all"
          ? `No ${props.qr.shortId}`
          : `No ${getCountryCallingCode(props.countries[0])}-${
              props.countries.length
            }-${props.qr.shortId}`}
      </p>
      <img src={bell} className="qrdesign_2_img" style={{
        left:"50%"
      }} alt="bellimg" />
      <div
        style={{
          backgroundColor: "#fff",
          padding: 6,
          height: 175,
          width: 175,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          top: "10.5%",
          zIndex: 11,
          borderRadius: 6,
        }}
      >
        <QRCodeSVG
          size={158}
          style={{
            // padding: 10,
            top: 0,
          }}
          level="H"
          imageSettings={{
            src: require("../../assets/img/doorvi_round.jpg"),
            height: 35,
            width: 35,
            excavate: true,
          }}
          className="qrcode-bell"
          value={
            props.value ??
            "https://call.doorvi.co/details?vm=659549c6d46adbd47ca0e89a"
          }
        />
      </div>
    </div>
  );
};

export default QR_NEWBell_REC;
