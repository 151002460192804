import { Button } from "@mui/material";
import moment from "moment";
import AdminAuth from "../../utility/auth";
export const COLUMNS = [
  {
    Header: "name",
    accessor: "name",
    Cell: ({ row }) => {
      return row.original?.user?.name ? row.original?.user?.name.substring(0, 12):row.original?.account?.name?row.original?.account?.name.substring(0, 12):"Vani User";
    },
    Filter: () => {
      return null;
    },
  },
  {
    Header: "phoneNo/email",
    Cell: ({ row }) => {
      return row.original.user?.phoneNo
        ? row.original.user?.phoneNo?.substring(0, 50)
        : row.original.user?.email?row.original.user?.email:row.original?.account?.email?row?.original?.account?.email.substring(0,50):"No Data";
    },
    Filter: () => {
      return null;
    },
  },
  {
    Header: "referCode",
    Cell: ({ row }) => {
      return row.original.user?.referalCode;
    },
    Filter: () => {
      return null;
    },
  },
  {
    Header: "Requested Amount",
    Cell: ({ row }) => {
      return row.original?.requstedAmount;
    },
    Filter: () => {
      return null;
    },
  },
  {
    Header: "Total Amount Earned",
    Cell: ({ row }) => {
      return row.original?.user?.totalAmountEarned;
    },
    Filter: () => {
      return null;
    },
  },
  {
    Header: "Cur balance",
    Cell: ({ row }) => {
      return (
        (row.original?.user?.totalAmountEarned -
        row.original?.user?.totalAmountWithdrawn).toFixed(2)
      );
    },
    Filter: () => {
      return null;
    },
  },
  {
    Header: "withdrawl request",
    accessor: "status",
  },
  {
    Header: "createdAt",
    accessor: "createdAt",
    Cell: ({ value }) => {
      return moment(new Date(value)).format("DD-MM-YYYY hh:mm A");
    },
  },
  {
    Header: "Action",
    // accessor: "createdAt",
    Cell: (props) => {
      return (
        <div>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              AdminAuth.getInstance().eventEmitter.emit(
                "showEditRefer",
                props.row.original
              );
            }}
            variant="contained"
          >
            Update
          </Button>
        </div>
      );
    },
  },
];
