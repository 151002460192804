import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { EditAgentApi } from "../api";
import AdminAuth from "../utility/auth";

const EditAgent = (props: any) => {
  const agents = [
    { label: "Admin", value: "Admin" },
    { label: "Agent", value: "Agent" },
  ];
  const [loading, setLoading] = useState(false);
  const [agentName, setAgentName] = useState(props.agentData.name);
  const [agentEmail, setAgentEmail] = useState(props.agentData.email);
  const [agentPhoneno, setAgentPhoneno] = useState(props.agentData.phnNo);
  const [agentAddress, setAgentAddress] = useState(props.agentData.address);
  const [agentCode, setAgentCode] = useState(props.agentData.agentCode);
  const [agentPass, setAgentPass] = useState(props.agentData.password);
  const [agentType, setAgentType] = useState(
    props.agentData.agentType ?? "Agent"
  );

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!loading) {
      setLoading(true);
      const res = await EditAgentApi(
        props.agentData._id,
        agentName,
        agentCode,
        agentEmail,
        agentPass,
        agentAddress,
        agentPhoneno,
        agentType
      );
      if (res.isSuccess) {
        toast.success("agent edited ");
        AdminAuth.getInstance().eventEmitter.emit("refreshAgentData", null);
      } else {
        toast.error("something went wrong");
      }
      setLoading(false);
      setAgentName("");
      setAgentCode("");
      setAgentAddress("");
      setAgentEmail("");
      setAgentPass("");
      setAgentPhoneno("");
    } else {
      return;
    }
  };

  // const getDepos = async () => {
  //   const depos = await getAllDepo();
  //   if (depos && depos.data) {
  //     setDepos(depos.data.depos);
  //   } else {
  //     toast.error("depot not found");
  //   }
  //   console.log("depot", depos.data);
  // };

  // useEffect(() => {
  //   getDepos();
  // }, []);

  return (
    <div
      className="auth-container"
      style={{
        backgroundImage: "linear-gradient(to left, #fff,#fff)",
        width: "100%",
      }}
    >
      <form className="form" style={{ width: "100%" }} onSubmit={handleSubmit}>
        <p className="form-title">QR Detail</p>
        <input
          placeholder="Enter Agent Name"
          onChange={(e) => {
            setAgentName(e.target.value);
          }}
          type={"text"}
          value={agentName}
          required
        />
        <input
          placeholder="Enter Agent Code"
          onChange={(e) => {
            setAgentCode(e.target.value);
          }}
          type={"text"}
          value={agentCode}
          required
        />
        <input
          placeholder="Enter Agent Email"
          onChange={(e) => {
            setAgentEmail(e.target.value);
          }}
          type={"text"}
          value={agentEmail}
          required
        />
        <input
          placeholder="Enter Agent Phone Number"
          onChange={(e) => {
            setAgentPhoneno(e.target.value);
          }}
          type={"text"}
          value={agentPhoneno}
          required
        />
        <input
          placeholder="Enter Agent Address"
          onChange={(e) => {
            setAgentAddress(e.target.value);
          }}
          type={"text"}
          value={agentAddress}
          required
        />
        <input
          placeholder="Enter Agent Password"
          onChange={(e) => {
            setAgentPass(e.target.value);
          }}
          type={"text"}
          value={agentPass}
          required
        />

        <button>
          {loading ? <CircularProgress size={17} color="inherit" /> : "Submit"}
        </button>
      </form>
    </div>
  );
};

export default EditAgent;
