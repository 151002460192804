import React, { useState } from "react";
import {
  addDevice,
  addHome,
  addSampleOTP,
  getQrCodes,
  linkQrCodeWithHome,
  signUpUser,
} from "../api";
import { CircularProgress } from "@mui/material";
import toast from "react-hot-toast";

const UserSignup = () => {
  const [fullAddress, setFullAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [homeTitle, setHomeTitle] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [bridgeId, setBridgeId] = useState("");
  const [homeType, setHomeType] = useState("Hotel/OYO");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [timeZone, setTimezone] = useState("");
  const [otp, setOtp] = useState("");
  const [name, setName] = useState("");
  const [clientId, setClientId] = useState("");
  const [secretId, setSecretId] = useState("");
  const userType = "oyo_hotel_owner";

  const handleSubmit = async (e: any) => {
    console.log("handleSubmit home add")
    let isHomeSuccess = false;
    let isUserSuccess = false;
    let isOtpSuccess = false;
    let isDeviceAddSuccess = false;
    e.preventDefault();
    setLoading(true);
    const homeData = {
      homeTitle,
      fullAddress,
      homeType,
      videoCallEnable: true,
    };
    const homeRes = await addHome(homeData);
    console.log("homeRes");
    isHomeSuccess = homeRes._id;
    const userData = {
      phoneNumber,
      name,
      homeId: homeRes?._id,
      userType,
    };
    const userRes = await signUpUser(userData);
    isUserSuccess = userRes?._id;
    const otpBody = {
      phoneNumber,
      otp,
      canBeDeleted: false,
    };

    const getQRForHome = await getQrCodes(1);
    const assignQRWithHome = await linkQrCodeWithHome(
      getQRForHome._id,
      homeRes._id,
      userRes?._id
    );
    // const otpRes = await addSampleOTP(otpBody);
    // isOtpSuccess = otpRes.isSuccess;

    const deviceRes = await addDevice({
      home: homeRes._id,
      deviceId,
      bridgeId,
      clientId,
      secretId,
      timeZone,
    });
    isDeviceAddSuccess = deviceRes?._id;
    console.log({ isHomeSuccess, isUserSuccess, isOtpSuccess });

    if (
      isHomeSuccess &&
      isUserSuccess &&
      isDeviceAddSuccess &&
      assignQRWithHome
    ) {
      setPhoneNumber("");
      setOtp("");
      setName("");
      setHomeTitle("");
      setFullAddress("");
      setBridgeId("");
      setDeviceId("");
      setClientId("");
      setBridgeId("");
      setTimezone("");
      toast.success("Data submitted successfully");
    } else {
      toast.error("something went wrong");
    }

    setLoading(false);
  };

  return (
    <div className="adduser-container">
      <form className="form user-form" onSubmit={handleSubmit}>
        <div className="auth-logo">
          <img src={"logo2.png"} alt="logo" />
        </div>
        <p className="form-title">Add User</p>
        <input
          placeholder="Enter Name"
          onChange={(e) => {
            setName(e.target.value);
          }}
          value={name}
          type={"text"}
          required
        />
        <input
          placeholder="Enter Phone number"
          onChange={(e) => {
            setPhoneNumber(e.target.value);
          }}
          value={phoneNumber}
          type="number"
          required
        />
        <input
          placeholder="Enter Hotel Short Name
          "
          onChange={(e) => {
            setHomeTitle(e.target.value);
          }}
          value={homeTitle}
          type={"text"}
          required
        />
        <input
          placeholder="Enter Hotel Full Address"
          onChange={(e) => {
            setFullAddress(e.target.value);
          }}
          value={fullAddress}
          type={"text"}
          required
        />

        <input
          placeholder="Enter Device Id"
          onChange={(e) => {
            setDeviceId(e.target.value);
          }}
          value={deviceId}
          type={"text"}
        />
        <input
          placeholder="Enter Bridge Id"
          onChange={(e) => {
            setBridgeId(e.target.value);
          }}
          value={bridgeId}
          type={"text"}
        />
        <input
          placeholder="Enter Client Id"
          onChange={(e) => {
            setClientId(e.target.value);
          }}
          value={clientId}
          type={"text"}
        />
        <input
          placeholder="Enter Secret Id"
          onChange={(e) => {
            setSecretId(e.target.value);
          }}
          value={secretId}
          type={"text"}
        />
        <input
          placeholder="Enter Device Timezone with format:+00:00"
          onChange={(e) => {
            setTimezone(e.target.value);
          }}
          value={timeZone}
          type={"text"}
          required
        />
        <button>
          {loading ? <CircularProgress size={15} color="inherit" /> : "Submit"}
        </button>
      </form>
    </div>
  );
};

export default UserSignup;
