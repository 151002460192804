import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import moment from "moment";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function OrderDetail(props: any) {
  const handleClickOpen = () => {
    props.setOpen(true);
  };

  const handleClose = () => {
    props.setOpen(false);
  };
  console.log("row", props.row);

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div
          style={{
            padding: 10,
          }}
        >
          <h3
            style={{
              margin: "10px 0",
            }}
          >
            {props.row.original._id} Profile
          </h3>
          <p>Name : {props.row.original.name}</p>
          <p>
            phone Number :{" "}
            {props.row.original.phoneNo ??
              props.row.original.phoneNumber ??
              props.row.original.email}
          </p>
        </div>
        <div
          style={{
            padding: 10,
          }}
        >
          <h3>Address</h3>
          <p>Short Address : {props.row.original?.address1}</p>
          <p>Full Address : {props.row.original?.address2}</p>
          <p>Pincode : {props.row.original?.pinCode}</p>
          <p>City : {props.row.original.city}</p>
          <p>State : {props.row.original.state}</p>
        </div>
        <div
          style={{
            padding: 10,
          }}
        >
          <h3>Order Detail</h3>
          <p>
            order createdAt :
            {moment(new Date(props.row.original.createdAt)).format(
              " DD-MM-YYYY"
            )}
          </p>
          <p>orderStatus : {props.row.original.orderStatus}</p>
          <p>productTypee : {props.row.original.productType}</p>
          <p>razorpayOrderId : {props.row.original.razorpayOrderId}</p>
          <p>razorpayPaymentId : {props.row.original.razorpayPaymentId}</p>
        </div>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
