import moment from "moment";
import { SiWhatsapp } from "react-icons/si";
export const COLUMNS = [
  {
    Header: "name",
    accessor: "name",

    Filter: () => {
      return null;
    },
  },
  {
    Header: "paymentStatus",
    accessor: "paymentStatus",
    Filter: () => {
      return null;
    },
  },
  {
    Header: "purchasedVia",
    accessor: "purchasedVia",
    Filter: () => {
      return null;
    },
  },
  {
    Header: "createdAt",
    Cell: ({ row }) => {
      return moment(row.original?.purchasedOn).format("DD-MM-YYYY hh:mm A");
    },
  },
  {
    Header: "phoneNumber/email",
    Cell: ({ row }) => {
      return row.original.phoneNumber
        ? row.original.phoneNumber?.substring(0, 50)
        : row.original.email;
    },
  },
  {
    Header: "contact",
    Cell: ({ row }) => {
      return (
        <a
          target="_blank"
          rel="noreferrer"
          href={`https://api.whatsapp.com/send/?phone=${row.original?.phoneNumber}&text&type=phone_number&app_absent=0`}
        >
          <SiWhatsapp size={20} color="#030303" />
        </a>
      );
    },
  },
];
