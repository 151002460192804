import React from "react";
import QR_Bell from "./QR_Bell";
import QR_NEW from "./QR_NEW";

const QRBellGenerator = () => {
  return (
    <div className="qr-grid-design_2">
      {new Array(30).fill("").map(() => (
        // <QR_Bell />
        <QR_NEW />
      ))}
    </div>
  );
};

export default QRBellGenerator;
