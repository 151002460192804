import moment from "moment";
export const COLUMNS = [
  {
    Header: "name",
    accessor: "name",
    Cell: ({ value }) => {
      return value.substring(0, 12);
    },
    Filter: () => {
      return null;
    },
  },
  {
    Header: "phoneNo/email",
    Cell: ({ row }) => {
      return row.original.phoneNo
        ? row.original.phoneNo?.substring(0, 50)
        : row.original.email;
    },
    Filter: () => {
      return null;
    },
  },
  {
    Header: "city",
    accessor: "city",
    Filter: () => {
      return null;
    },
  },
  {
    Header: "razorpay payment id",
    Cell: ({ row }) => {
      return `${row.original.razorpayPaymentId ?? "None"}`;
    },
  },
  {
    Header: "createdAt",
    accessor: "createdAt",
    Cell: ({ value }) => {
      return moment(new Date(value)).format("DD-MM-YYYY hh:mm A");
    },
  },
  {
    Header: "price",
    // accessor: "finalPrice",
    Cell: ({ row }) => {
      return `${row.original?.sku.currency ?? "INR"} ${
        row.original.finalPrice
      }(${row.original.quantity})`;
    },
  },
  {
    Header: "orderStatus",
    accessor: "orderStatus",
  },

  {
    Header: "productType",
    accessor: "productType",
  },
];
