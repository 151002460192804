import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmationDialog(props: any) {
  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent
          style={{
            fontFamily: "poppins",
            color: "#000 !important",
          }}
        >
          <DialogContentText
            style={{
              fontFamily: "poppins",
              color: "#000 !important",
            }}
            id="alert-dialog-slide-description"
          >
            {`Do you want to deactivate QR-${props.qrshortId}?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              fontFamily: "poppins",
              color: "#000 !important",
            }}
            onClick={props.handleClose}
          >
            Cancel
          </Button>
          <Button onClick={props.handleOk}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
