import { QRCodeSVG } from "qrcode.react";
import bell from "../../assets/img/bellDemandSticker.svg";
import { getCountryCallingCode } from "libphonenumber-js";

const Qr_NewDemandBell = (props: any) => {
  return (
    <div className="qr-wrap-bell">
      <p className="qr-id-bell" style={{
        bottom: "58px",
        right: "43px",
        fontSize:"8px"
      }}>
        {props.countries[0] === "all"
          ? `No ${props.qr.shortId}`
          : `No ${getCountryCallingCode(props.countries[0])}-${props.countries.length
          }-${props.qr.shortId}`}
      </p>
      <img src={bell} className="qrbell" alt="bellimg" style={{height:"119%",width:"124%"}}/>
      <QRCodeSVG
        size={180}
        level="H"
        imageSettings={{
          src: require("../../assets/img/doorvi_round.jpg"),
          height: 35,
          width: 35,
          excavate: true,
        }}
        className="qrcode-bell"
        value={props.value}
      />
    </div>
  );
};

export default Qr_NewDemandBell;
