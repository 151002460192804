import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  TableContainer,
  makeStyles,
  Button,
  CircularProgress,
} from "@material-ui/core";
import {
  useTable,
  useSortBy,
  useFilters,
  usePagination,
  useGlobalFilter,
} from "react-table";
import ReactTable from "react-table";
import { IoArrowDown, IoArrowUp } from "react-icons/io5";
import { COLUMNS } from "./Column";
import { uris } from "../../api/baseUrl";
import "../../styles/table.css";
import TableHeaderAction from "./TableHeaderAction";
import AccountDetail from "./AccountDetail";
import axios from "axios";
import { setDateRange, setStatusFilterValue } from "../../actions";

const ReferUserTable = (props) => {
  const csvDataRef = useRef([]);
  const useStyles = makeStyles({
    container: {
      minHeight: 250,
      maxHeight: 650,
    },
  });
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [clickedRow, setClickedRow] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [dateRange, setDateRange] = useState(null);
  const [PageCount, setPageCount] = useState(-1);
  const [paymentstatus, setPaymentstatus] = useState("all");

  function reset() {
    console.log("dd");
    setData([]);
    setSearchText("");
    setDateRange(null);
    fetchData();
    setPagination({ pageIndex: 0, pageSize: 10 });
    setPageCount(-1);
    csvDataRef.current = [];
  }

  const fetchData = async (page, limit = props.twoHomeApi ? 300 : 10) => {
    console.log("inside");
    setLoading(true);
    try {
      const res = await axios.get(
        uris.referUrl +
          `/withdrawals/allWithdrawalRequestsWithStatus/${paymentstatus}/${pagination.pageIndex}/${limit}`
      );
      if (res.data.isSuccess) {
        setPageCount(res.data.data.count);
        setLoading(false);
        setData(res.data.data.requests || []);
      }
      console.log(res.data);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const renderToggleIcon = (column) => {
    if (column.isSorted) {
      if (column.isSortedDesc) {
        return (
          <IoArrowDown
            color="#fff"
            onClick={() => column.toggleSortBy(false)}
          />
        );
      } else {
        return (
          <IoArrowUp color="#fff" onClick={() => column.toggleSortBy(true)} />
        );
      }
    } else {
      return;
    }
  };
  const {
    getTableProps,
    page,
    state,
    setGlobalFilter,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setFilter,
    pageOptions,
  } = useTable(
    {
      columns: COLUMNS,
      data,
      state: {
        pagination,
      },
      initialState: {
        sortBy: [],
        pageSize: 11,
      },
      pageCount: Math.ceil(PageCount / 10),
      manualPagination: true,
      onPaginationChange: setPagination,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const { globalFilter, pageIndex } = state;
  useEffect(() => {
    // props.em.addListener("onRefresh", reset);
  }, []);
  useEffect(() => {
    csvDataRef.current = [];
    fetchData();
  }, [pagination.pageIndex, searchText, paymentstatus]);

  const createRowsFromFilter = (row, index) => {
    // csvDataRef.current = [];
    console.log("dawaw");
    csvDataRef.current.push(row.original);
    prepareRow(row);
    return (
      <TableRow
        hover
        key={index}
        onClick={() => {
          setOpen(true);
          setClickedRow(row);
        }}
        {...row.getRowProps()}
      >
        {row.cells.map((cell) => {
          return (
            <TableCell {...cell.getCellProps()}>
              {cell.render("Cell")}
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  return (
    <>
      <div className="table__wrap">
        <div
          className="table__header-action-container"
          // style={{ pointerEvents: "none" }}
        >
          <TableHeaderAction
            filter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            setFilter={setFilter}
            setDateRangeFilter={setDateRange}
            setStatusFilterValue={setStatusFilterValue}
            paymentstatus={paymentstatus}
            setPaymentstatus={setPaymentstatus}
            // fetchOrdersData={fetchOrdersData}
          />
        </div>
        {data && data.length > 0 && !loading ? (
          <TableContainer
            style={{ height: "90% !important" }}
            className={classes.container}
          >
            <Table stickyHeader className="table" {...getTableProps()}>
              <TableHead>
                {headerGroups.map((headerGroup) => {
                  return (
                    <TableRow
                      className="table__header-row"
                      {...headerGroup.getHeaderGroupProps()}
                    >
                      {headerGroup.headers.map((column, i) => {
                        return (
                          <TableCell key={i} {...column.getHeaderProps()}>
                            {column.render("Header")}
                            <span>{renderToggleIcon(column)}</span>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableHead>
              <TableBody {...getTableBodyProps()}>
                {page.map((row, index) => {
                  console.log("index", index);
                  return createRowsFromFilter(row, index);
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}

        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-around",
            alignItems: "center",
            margin: 0,
          }}
        >
          <Button
            onClick={() => {
              setPagination({
                ...pagination,
                pageIndex: pagination.pageIndex - 1,
              });
            }}
            disabled={pagination.pageIndex <= 0}
            variant="outlined"
          >
            Prev
          </Button>
          <div>
            <p>
              {pagination.pageIndex + 1} of {pageOptions.length}
            </p>
          </div>
          <Button
            style={{
              fontWeight: "600",
            }}
            onClick={() => {
              setPagination({
                ...pagination,
                pageIndex: pagination.pageIndex + 1,
              });
            }}
            disabled={
              pagination.pageIndex + 1 >= PageCount / pagination.pageSize
            }
            variant="outlined"
          >
            Next
          </Button>
        </div>
        {data[0] && clickedRow && (
          <AccountDetail row={clickedRow} open={open} setOpen={setOpen} />
        )}
      </div>
    </>
  );
};

export default ReferUserTable;
