import React, { Component } from "react";
import QRGenrator from "../components/QRGenrator";
import "../styles/qr.css";
import Modal from "../components/Modal";
import Loader from "../components/Loader";
import htmlToCanvas from "html2canvas";
import jspdf from "jspdf";
import baseUrl, { uris } from "../api/baseUrl";
import BTDCQRGenrator from "../components/qrtypes/BTDC_QR";
import HotelQRGenrator from "../components/HotelQr";
import CustomQr from "./customSticker/CustomQr";
import GardeniaQRGenrator from "../components/qrtypes/Gardenia";
import QR_Bell from "../components/qrtypes/QR_Bell";
import QR_NEW from "../components/qrtypes/QR_NEW";
import QR_NEWBell from "../components/qrtypes/QR_NEWBell";
import QR_NEWBell_REC from "../components/qrtypes/QR_NEWBell_REC";
import Qr_NewDemandBell from "../components/qrtypes/Qr_NewDemandBell";
import BlackDoorvi from "../components/qrtypes/BlackDoorvi";
import QR_Blank from "../components/qrtypes/QR_Blank";
import QRGenratorBlack from "../components/QRGenratorBlack";
import QR_BlackBell from "../components/qrtypes/QR_BlackBell";
interface QRHandlerProps {
  history: any;
}

interface QRHandlerState {
  loading: boolean;
  imagesComponentRef: Array<any>;
  inputValue: number;
  qrs: Array<any>;
  progress: any;
}
export default class QRHandler extends Component<
  QRHandlerProps,
  QRHandlerState
> {
  private qrContainerRef: any;
  private gapAfterNoOfElement: any;
  constructor(props: QRHandlerProps) {
    super(props);
    this.state = {
      loading: false,
      imagesComponentRef: [],
      inputValue: 0,
      qrs: [],
      progress: 0,
    };
    this.gapAfterNoOfElement = 4 * 4;
    this.qrContainerRef = React.createRef();
  }

  getQrType = () => {
    switch (this.props.history.location.state.qrCategory) {
      case "BTDC":
        return "home";
      case "vehicle":
        return "vehicle";
      case "lostandfound":
        return "lostandfound";
      case "Gardenia":
        return "home";
      default:
        return "home";
    }
  };
  componentDidMount() {
    let { number, copy, qrCategory, countryCodes, subscriptionPlans, agent } =
      this.props.history.location.state;
    if (copy < 1) {
      copy = 1;
    }
    this.setState({ loading: true });

    this.fetchQR(
      number,
      copy,
      this.getQrType(),
      countryCodes,
      agent,
      subscriptionPlans
    );
  }

  fetchQR = (
    numberOfQrCodes: number,
    copy: number,
    qrCategory: string,
    allowedCountries: string,
    agent: string,
    subscriptionPlans: any
  ) => {
    console.log("genrateQRCodes", {
      numberOfQrCodes,
      type: qrCategory,
      allowedCountries,
      subscriptionPlans,
      isCreatedByAdmin: true,
    });
    baseUrl
      .post(
        "/genrateQRCodes",
        {
          numberOfQrCodes,
          type: qrCategory,
          allowedCountries,
          agent,
          subscriptionPlans: [
            ...subscriptionPlans,
            {
              subscriptionUniqueName: "com.doorvi.basic",
              subscriptionValidTillInMonth: -1,
            },
          ],
          isCreatedByAdmin: true,
        },
        {
          onUploadProgress: (progress) => {
            const total = (progress.loaded / progress.total) * 100;
            this.setState({ progress: total });
          },
        }
      )
      .then((response) => {
        const finalQrs: any[] = [];
        response.data.data.forEach((qr: any) => {
          for (let i = 1; i <= copy; i++) {
            finalQrs.push(qr);
          }
        });
        this.setState({ qrs: finalQrs, loading: false });
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
  };

  renderCustomQr = () => {
    const images = [
      require("./customSticker/qrs/14.png"),
      require("./customSticker/qrs/15.png"),
      require("./customSticker/qrs/16.png"),
      require("./customSticker/qrs/17.png"),
      require("./customSticker/qrs/18.png"),
      require("./customSticker/qrs/19.png"),
      require("./customSticker/qrs/20.png"),
    ];
    const el: any = [];
    let count = 0;
    this.state.qrs.map((qr, i) => {
      el.push(
        <CustomQr
          index={i}
          countries={this.props.history.location.state.countryCodes}
          qrType={this.props.history.location.state.qrCategory}
          number={this.props.history.location.state.number}
          img={images[count]}
          value={`${uris.callProductionUri}${qr._id}`}
          key={i}
          qr={qr}
        />
      );
      count === 6 ? (count = 0) : count++;
    });
    return el;
  };

  handleHtmlToCanvas = async () => {
    const jspdfObj = new jspdf();
    const el: any = document.querySelectorAll(".qr-grid");
    console.log("el", el);
    const res: any = [];
    el.forEach((e: any) => {
      htmlToCanvas(e).then(async (canvas) => {
        const img: any = await canvas.toDataURL("img/png");
        console.log(img);
        await jspdfObj.addImage(img, "JPEG", 140, 15, 90, 90, "alias1", "SLOW");
      });
    });
    await jspdfObj.save();
  };
  render() {
    console.log(
      "this.props.history.location.state.qrCategory",
      this.props.history.location.state.qrCategory
    );
    const { qrCategory, design } = this.props.history.location.state;
    return (
      <>
        {this.state.loading && (
          <Modal>
            <Loader progress={this.state.progress} />
          </Modal>
        )}
        {(qrCategory === "home" || qrCategory === "vehicle") &&
          design === "card" && (
            <div
              className={qrCategory === "home" ? "qr-grid" : "qr-grid-vehicle"}
              style={{
                backgroundColor: qrCategory === "home" ? "#067eee" : "#fff",
              }}
            >
              {this.state.qrs.map((qr, i) => (
                <QRGenrator
                  index={i}
                  qrType={this.props.history.location.state.qrCategory}
                  value={`${uris.callProductionUri}${qr._id}`}
                  key={i}
                  qr={qr}
                  countries={this.props.history.location.state.countryCodes}
                />
              ))}
            </div>
          )}
          {(qrCategory === "home" || qrCategory === "vehicle") &&
          design === "blackCard" && (
            <div
              className={qrCategory === "home" ? "qr-grid" : "qr-grid-vehicle"}
              style={{
                backgroundColor: qrCategory === "home" ? "#000" : "#000",
              }}
            >
              {this.state.qrs.map((qr, i) => (
                <QRGenratorBlack
                  index={i}
                  qrType={this.props.history.location.state.qrCategory}
                  value={`${uris.callProductionUri}${qr._id}`}
                  key={i}
                  qr={qr}
                  countries={this.props.history.location.state.countryCodes}
                />
              ))}
            </div>
          )}
        {qrCategory === "home" && design === "bell" && (
          <div className="qr-grid-bell">
            {this.state.qrs.map((qr, i) => (
              // eslint-disable-next-line react/jsx-pascal-case
              <QR_Bell
                index={i}
                qrType={this.props.history.location.state.qrCategory}
                value={`${uris.callProductionUri}${qr._id}`}
                key={i}
                qr={qr}
                countries={this.props.history.location.state.countryCodes}
              />
            ))}
          </div>
        )}
        {qrCategory === "home" && design === "blankQr" && (
          <div className="qr-grid-design_2_blank">
            {this.state.qrs.map((qr, i) => (
              // eslint-disable-next-line react/jsx-pascal-case
              <QR_Blank
                index={i}
                qrType={this.props.history.location.state.qrCategory}
                value={`${uris.callProductionUri}${qr._id}`}
                key={i}
                qr={qr}
                countries={this.props.history.location.state.countryCodes}
              />
            ))}
          </div>
        )}
        {qrCategory === "home" && design === "longsize" && (
          <div className="qr-grid-design_2">
            {this.state.qrs.map((qr, i) => (
              // eslint-disable-next-line react/jsx-pascal-case
              <QR_NEW
                index={i}
                qrType={this.props.history.location.state.qrCategory}
                value={`${uris.callProductionUri}${qr._id}`}
                key={i}
                qr={qr}
                countries={this.props.history.location.state.countryCodes}
              />
            ))}
          </div>
        )}
        {qrCategory === "home" && design === "demandBell" && (
          <div
            className="qr-grid-design_2"
            style={{
              gridTemplateColumns: "repeat(auto-fit, minmax(100px, 520px))",
              gridRowGap: "144px",
              paddingTop: "75px",
            }}
          >
            {this.state.qrs.map((qr, i) => (
              // eslint-disable-next-line react/jsx-pascal-case
              <Qr_NewDemandBell
                index={i}
                qrType={this.props.history.location.state.qrCategory}
                value={`${uris.callProductionUri}${qr._id}`}
                key={i}
                qr={qr}
                countries={this.props.history.location.state.countryCodes}
              />
            ))}
          </div>
        )}
        {qrCategory === "home" && design === "longsizebell" && (
          <div className="qr-grid-design_2">
            {this.state.qrs.map((qr, i) => (
              // eslint-disable-next-line react/jsx-pascal-case
              <QR_NEWBell
                index={i}
                qrType={this.props.history.location.state.qrCategory}
                value={`${uris.callProductionUri}${qr._id}`}
                key={i}
                qr={qr}
                countries={this.props.history.location.state.countryCodes}
              />
            ))}
          </div>
        )}
        {qrCategory === "home" && design === "blackBell" && (
          <div className="qr-grid-bell" style={{backgroundColor:"#000"}}>
          {this.state.qrs.map((qr, i) => (
            // eslint-disable-next-line react/jsx-pascal-case
            <QR_BlackBell
              index={i}
              qrType={this.props.history.location.state.qrCategory}
              value={`${uris.callProductionUri}${qr._id}`}
              key={i}
              qr={qr}
              countries={this.props.history.location.state.countryCodes}
            />
          ))}
        </div>          
        )}
        {qrCategory === "home" && design === "longsizebellrec" && (
          <div className="qr-grid-design_2" >
            {this.state.qrs.map((qr, i) => (
              // eslint-disable-next-line react/jsx-pascal-case
              <QR_NEWBell_REC
                index={i}
                qrType={this.props.history.location.state.qrCategory}
                value={`${uris.callProductionUri}${qr._id}`}
                key={i}
                qr={qr}
                countries={this.props.history.location.state.countryCodes}
              />
            ))}
          </div>
        )}
        {this.props.history.location.state.qrCategory === "lostandfound" && (
          <div
            className={"qr-grid"}
            style={{
              backgroundColor: "#fff",
              gridTemplateColumns: "repeat(auto-fit, minmax(100px, 140px))",
              // gridRowGap: "32px",
              // gridColumnGap: "32px",
              gap: 25.7,
              paddingBottom: 15,
              paddingLeft: 25,
              paddingTop: 12.85,
            }}
          >
            {this.renderCustomQr()}
          </div>
        )}
        {this.props.history.location.state.qrCategory === "BTDC" && (
          <div className="qr-grid">
            {this.state.qrs.map((qr, i) => (
              <BTDCQRGenrator
                index={i}
                countries={this.props.history.location.state.countryCodes}
                qrType={this.props.history.location.state.qrCategory}
                value={`${uris.callProductionUri}${qr._id}`}
                key={i}
                qr={qr}
              />
            ))}
          </div>
        )}
        {qrCategory === "home" && design == "blackDoorvi" && (
          <div className="qr-grid-black">
            {this.state.qrs.map((qr, i) => (
              <BlackDoorvi
                index={i}
                countries={this.props.history.location.state.countryCodes}
                qrType={this.props.history.location.state.qrCategory}
                value={`${uris.callProductionUri}${qr._id}`}
                key={i}
                qr={qr}
              />
            ))}
          </div>
        )}
        {this.props.history.location.state.qrCategory === "Gardenia" && (
          <div className="qr-grid">
            {this.state.qrs.map((qr, i) => (
              <GardeniaQRGenrator
                index={i}
                countries={this.props.history.location.state.countryCodes}
                qrType={this.props.history.location.state.qrCategory}
                value={`${uris.callProductionUri}${qr._id}`}
                key={i}
                qr={qr}
              />
            ))}
          </div>
        )}
        {this.props.history.location.state.qrCategory === "hotel" && (
          <div
            className="qr-grid"
            style={{
              backgroundColor: "#067eee",
            }}
          >
            {this.state.qrs.map((qr, i) => (
              <HotelQRGenrator
                index={i}
                countries={this.props.history.location.state.countryCodes}
                qrType={this.props.history.location.state.qrCategory}
                value={`${uris.callProductionUri}${qr._id}`}
                key={i}
                qr={qr}
              />
            ))}
          </div>
        )}
      </>
    );
  }
}
