import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AccountDetail(props: any) {
  const handleClickOpen = () => {
    props.setOpen(true);
  };

  const handleClose = () => {
    props.setOpen(false);
  };
  console.log("row", props.row);

  return (
    <div>
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <div
          style={{
            padding: 10,
          }}
        >
          <h3
            style={{
              margin: "10px 0",
            }}
          >
            {props.row.original?.user?._id} Profile
          </h3>
          <p>Name : {props.row.original?.user?.name}</p>
          <p>
            phone Number/email :{" "}
            {props.row.original?.user?.phoneNumber ??
              props.row.original?.user?.email ??
              props.row.original?.user?.phoneNo}
          </p>
          a
          <p>
            Total amount earned :{" "}
            {props.row.original?.user?.totalAmountEarned.toFixed(4)}
          </p>
          <p>
            Total amount Withdrawn :{" "}
            {props.row.original?.user?.totalAmountWithdrawn}
          </p>
          <h3
            style={{
              margin: "10px 0",
            }}
          >
            Account Details
          </h3>
          <p>Bank Name : {props.row.original?.account?.bankName}</p>
          <p>Account Number : {props.row.original?.account?.accountNo}</p>
          <p>Account Type : {props.row.original.account?.accountType}</p>
          <p>Bank Address : {props.row.original?.account?.bankAddress}</p>
          <p>paypal Id : {props.row.original?.account?.paypalId}</p>
          <p>swiftOrBICNumber : {props.row.original?.account?.swiftOrBICNumber}</p>
          <p>Name : {props.row.original?.account?.name}</p>
          <p>Email : {props.row.original?.account?.email}</p>
          
        </div>

        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
