var events = require("events");

class AdminAuth {
  static instance: AdminAuth;
  isLoggedIn: boolean;
  userData: any = undefined;
  eventEmitter = new events.EventEmitter();
  constructor() {
    this.isLoggedIn = false;
  }
  static getInstance() {
    if (!AdminAuth.instance) {
      AdminAuth.instance = new AdminAuth();
    }
    return AdminAuth.instance;
  }

  setAuth(val: boolean) {
    this.isLoggedIn = val;
  }

  getAuth() {
    return this.isLoggedIn;
  }
}

export default AdminAuth;
