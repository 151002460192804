import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import i18n from "../i18n/config";
import ReactSelect from "react-select";
import { country } from "../utility/countries";
import { getAllPlans } from "../api";
import baseUrl from "../api/baseUrl";

const qrCategories = [
  { label: "home", value: "home" },
  { label: "vehicle", value: "vehicle" },
  { label: "BTDC", value: "BTDC" },
  { label: "hotel", value: "hotel" },
  { label: "lost and found", value: "lostandfound" },
  { label: "Gardenia", value: "Gardenia" },
];
const designs = [
  { label: "Card", value: "card" },
  { label: "Bell", value: "bell" },
  { label: "Bell - Boris France", value: "demandBell" },
  { label: "Long Size", value: "longsize" },
  { label: "Long Size Bell", value: "longsizebell" },
  { label: "Long Size Bell Rectangle", value: "longsizebellrec" },
  {label:"Black Square",value:"blackDoorvi"},
  { label: "Blank Qr", value: "blankQr" },
  { label: "Black Card", value: "blackCard" },
  { label: "Black Bell", value: "blackBell" },
];
const language = [
  { label: "english", value: "en" },
  { label: "Pусский", value: "ru" },
  { label: "Português", value: "pt" },
  { label: "Français", value: "fr" },
];
const planvalidaityInMonth = [
  { label: "one month", value: 1 },
  { label: "3 month", value: 3 },
  { label: "6 month", value: 6 },
  { label: "9 month", value: 9 },
  { label: "12 month", value: 12 },
  { label: "lifetime", value: -1 },
];
export default function QRDialog(props: any) {
  const [number, setNumber] = React.useState(0);
  const [plans, setPlans] = React.useState([]);
  const [agentData, setAgentData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [plan, setPlan] = React.useState<any>([
    {
      subscriptionUniqueName: "com.doorvi.basic",
      subscriptionValidTillInMonth: -1,
    },
  ]);
  const [planvalidaity, setPlanvalidaity] = React.useState<any>(undefined);
  const [countries, setCountries] = React.useState<any>([country[0]]);
  const [copy, setCopy] = React.useState(0);
  const [qrCategory, setQrCategory] = React.useState("home");
  const [design, setDesign] = React.useState("card");
  const [lang, setLang] = React.useState("en");
  const [agent, setAgent] = React.useState<any>(undefined);

  console.log(props.history);
  const handleQR = () => {
    const countryCodes = countries.map((c: any) => c.value);
    console.log(plan, planvalidaity);

    props.history.push({
      pathname: "qrs",
      state: {
        number,
        copy,
        qrCategory,
        countryCodes,
        design,
        subscriptionUniqueName: plan,
        subscriptionValidTillInMonth: planvalidaity,
        subscriptionPlans: plan,
        agent,
      },
    });
    handleLanguageChange(lang);
  };

  const handleAllPlans = async () => {
    const plans = await getAllPlans();
    const newPlan = plans?.data?.map((plan: any, i: number) => {
      return { label: plan.name, value: plan.subscriptionUniqueName };
    });
    setPlans(newPlan ?? []);
  };
  React.useEffect(() => {
    handleAllPlans();
    fetchAgentData();
  }, []);

  const fetchAgentData = async () => {
    setLoading(true);
    try {
      const res = await baseUrl.get(`/listAgents`);
      if (res.data.isSuccess) {
        setLoading(false);
        setAgentData(res.data.data);
      }
      console.log(res.data);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleLanguageChange = (language: string) => {
    console.log("language", language);

    i18n.changeLanguage(language);
  };
  return (
    <>
      <Dialog
        style={{
          fontFamily: "inherit",
          // height:400
        }}
        open={props.open}
        onClose={props.handleClose}
      >
        <DialogContent>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <DialogContentText
              style={{
                fontFamily: "inherit",
              }}
            >
              Genrate QR codes
            </DialogContentText>
            <FormControl>
              <InputLabel id="demo-simple-select-label">design type</InputLabel>
              <Select
                disabled={qrCategory !== "home"}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={design}
                label="Select qr type"
                onChange={(e) => {
                  setDesign(e.target.value);
                }}
              >
                {designs.map((de: any) => (
                  <MenuItem value={de.value}>{de.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <InputLabel id="demo-simple-select-label">
                Select qr type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={qrCategory}
                label="Select qr type"
                onChange={(e) => {
                  setQrCategory(e.target.value);
                }}
              >
                {qrCategories.map((qr: any) => (
                  <MenuItem value={qr.value}>{qr.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="No of QR code"
            type="number"
            fullWidth
            style={{
              fontFamily: "inherit",
            }}
            onChange={(e) => {
              setNumber(parseInt(e.target.value));
            }}
            value={number}
            variant="standard"
          />
          <TextField
            margin="dense"
            id="name"
            label="No of copy for QR code"
            type="number"
            fullWidth
            style={{
              fontFamily: "inherit",
            }}
            onChange={(e) => {
              setCopy(parseInt(e.target.value));
            }}
            value={copy}
            variant="standard"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="demo-simple-select-label-lang">
              Select language
            </InputLabel>
            <Select
              labelId="demo-simple-select-label-lang"
              id="demo-simple-select-lang"
              label="language-lang"
              value={lang}
              onChange={(e) => {
                console.log(e.target.value);
                setLang(e.target.value);
                handleLanguageChange(e.target.value);
              }}
            >
              {language.map((lang: any) => (
                <MenuItem value={lang.value}>{lang.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel id="demo-simple-select-label-lang">
              Select Agent
            </InputLabel>
            <Select
              labelId="demo-simple-select-label-lang"
              id="demo-simple-select-lang"
              label="language-lang"
              value={agent}
              onChange={(e) => {
                console.log(e.target.value);
                setAgent(e.target.value);
              }}
            >
              {agentData.map((agent: any) => (
                <MenuItem value={agent._id}>
                  {agent.name}-{agent.agentCode}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel id="demo-simple-select-label-plan">
              Select plan
            </InputLabel>
            <Select
              labelId="demo-simple-select-label-plan"
              id="demo-simple-select-plan"
              label="plan"
              value={plan[0].subscriptionUniqueName}
              onChange={(e) => {
                console.log(e.target.value);
                setPlan([
                  { ...plan[0], subscriptionUniqueName: e.target.value },
                ]);
              }}
            >
              {plans.map((plan: any) => (
                <MenuItem value={plan.value}>{plan.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel id="demo-simple-select-label-planvalidaity">
              Select validity
            </InputLabel>
            <Select
              labelId="demo-simple-select-label-planvalidaity"
              id="demo-simple-select-planvalidaity"
              label="planvalidaity"
              value={plan[0].subscriptionValidTillInMonth}
              onChange={(e) => {
                // setPlanvalidaity(e.target.value);
                setPlan([
                  { ...plan[0], subscriptionValidTillInMonth: e.target.value },
                ]);
              }}
            >
              {planvalidaityInMonth.map((plan: any) => (
                <MenuItem value={plan.value}>{plan.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <ReactSelect
            options={country}
            defaultValue={[country[0]]}
            onChange={(data: any) => {
              console.log("select", data.value);
              setCountries(data);
            }}
            menuPosition="fixed"
            // menuPortalTarget={"css-uhb5lp"}
            // menu
            isMulti={true}
          />
        </DialogContent>
        <DialogActions>
          <Button
            style={{
              fontFamily: "inherit",
            }}
            onClick={props.handleClose}
          >
            Cancel
          </Button>
          <Button
            style={{
              fontFamily: "inherit",
            }}
            onClick={handleQR}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
