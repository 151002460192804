import React, { useEffect, useState } from "react";
import baseUrl from "../api/baseUrl";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import toast from "react-hot-toast";
import { getAllPlans } from "../api";

const Subsription = () => {
  const [create, setCreate] = useState(1);
  const [curData, setCurData] = useState(0);
  const [plans, setPlans] = useState([]);
  const [plansData, setPlansData] = useState([]);
  const [plan, setPlan] = useState<any>("");
  const [grade, setGrade] = useState("");
  const [planName, setPlanName] = useState("");
  const [planNameUnique, setPlanNameUnique] = useState("");
  const [noOfMembers, setMembers] = useState("");
  const [notificationAlert, setNotificationAlert] = useState(0);
  const [customizeQRCode, setCustomizeQRCode] = useState(0);
  const [cloudRecording, setCloudRecording] = useState(0);
  const [callLogs, setCallLogs] = useState(0);
  const [snapshotCapture, setSnapshotCapture] = useState(0);
  const [unlimitedCall, setUnlimitedCall] = useState(0);
  const [qrSoftCopy, setQrSoftCopy] = useState(0);
  const [stickerSupport, setStickerSupport] = useState(0);
  const [vipCustomerCare, setVipCustomerCare] = useState(0);
  const [twoWayVideoCall, setTwoWayVideoCall] = useState(0);
  const [premiumWeb, setPremiumWeb] = useState(0);
  const [videoCallTime, setVideoCallTime] = useState<any>(-1);

  const handleAddEdit = async () => {
    const payload = {
      grade: parseInt(grade),
      name: planName,
      subscriptionUniqueName: planNameUnique,
      noOfMembers: parseInt(noOfMembers),
      notificationAlert: !!notificationAlert,
      customizeQRCode: !!customizeQRCode,
      cloudRecording: !!cloudRecording,
      callLogs: !!callLogs,
      snapshotCapture: !!snapshotCapture,
      stickerSupport: !!stickerSupport,
      vipCustomerCare: !!vipCustomerCare,
      twoWayVideoCall: !!twoWayVideoCall,
      unLimitedVideo: !!unlimitedCall,
      qrCodeSoftCopy: !!qrSoftCopy,
      premiumWeb: !!premiumWeb,
      videoCallTimeInSec: parseInt(videoCallTime),
    };
    console.log(payload);
    const res = await baseUrl.post("/addEditSubscriptionPlan", payload);

    if (res?.data && res.data.isSuccess) {
      toast.success(`${planName} ${create ? "added" : "edited"}`);
      create && resetData();
    }
  };

  const handleAllPlans = async () => {
    const plans = await getAllPlans();
    const newPlan = plans?.data?.map((plan: any, i: number) => {
      return { label: plan.name, value: i };
    });
    setPlans(newPlan ?? []);
    setPlansData(plans.data ?? []);
  };
  const handleUpdatePlan = async (i: any) => {
    const currentPlan: any = plansData[i];
    setCurData(1);
    setPlanName(currentPlan.name);
    setPlanNameUnique(currentPlan.subscriptionUniqueName);
    setMembers(currentPlan.noOfMembers);
    setGrade(currentPlan.grade);
    setNotificationAlert(currentPlan.notificationAlert ? 1 : 0);
    setCustomizeQRCode(currentPlan.customizeQRCode ? 1 : 0);
    setCloudRecording(currentPlan.cloudRecording ? 1 : 0);
    setCallLogs(currentPlan.callLogs ? 1 : 0);
    setSnapshotCapture(currentPlan.snapshotCapture ? 1 : 0);
    setStickerSupport(currentPlan.stickerSupport ? 1 : 0);
    setVipCustomerCare(currentPlan.vipCustomerCare ? 1 : 0);
    setTwoWayVideoCall(currentPlan.twoWayVideoCall ? 1 : 0);
    setQrSoftCopy(currentPlan.qrCodeSoftCopy ? 1 : 0);
    setUnlimitedCall(currentPlan.unLimitedVideo ? 1 : 0);
    setPremiumWeb(currentPlan.premiumWeb ? 1 : 0);
    setVideoCallTime(currentPlan.videoCallTimeInSec ?? -1);
  };
  const resetData = async () => {
    setPlanName("");
    setPlanNameUnique("");
    setMembers("");
    setGrade("");
    setNotificationAlert(0);
    setCustomizeQRCode(0);
    setCloudRecording(0);
    setCallLogs(0);
    setSnapshotCapture(0);
    setStickerSupport(0);
    setVipCustomerCare(0);
    setTwoWayVideoCall(0);
    setPremiumWeb(0);
    setVideoCallTime(-1);
  };

  useEffect(() => {
    handleAllPlans();
  }, []);

  return (
    <div className="">
      <p>subsription</p>
      {plans.length > 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              marginRight: 20,
            }}
          >
            <input
              type="radio"
              id="create"
              name="create"
              onClick={() => {
                resetData();
                setCreate(1);
              }}
              value={1}
              checked={create === 1}
            />
            <label htmlFor="create">Create plan</label>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <input
              type="radio"
              id="create0"
              onClick={() => {
                resetData();
                setCreate(0);
              }}
              name="create"
              value={0}
              checked={create === 0}
            />
            <label htmlFor="create0">Update plan</label>
          </div>
        </div>
      )}

      {!create && (
        <FormControl style={{ width: 200, margin: "15px 0" }}>
          <InputLabel id="demo-simple-select-label">Select plan</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={plan}
            label="Select qr type"
            onChange={(e) => {
              setPlan(e.target.value);
              handleUpdatePlan(e.target.value);
            }}
          >
            {plans.map((qr: any) => (
              <MenuItem value={qr.value}>{qr.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {((!create && curData) || create) && (
        <table className="table">
          <tr>
            <th>features</th>
            <th>feature update</th>
          </tr>

          <tr>
            <td>plan name</td>
            <td>
              <input
                placeholder="Enter plan name"
                value={planName}
                onChange={(e) => setPlanName(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>grade</td>
            <td>
              <input
                placeholder="Enter plan grade"
                value={grade}
                onChange={(e) => setGrade(e.target.value)}
              />
            </td>
          </tr>
          <tr>
            <td>no of members</td>
            <td>
              <>
                <input
                  type="number"
                  value={noOfMembers}
                  onChange={(e) => setMembers(e.target.value)}
                  placeholder="Enter no of member"
                />
              </>
            </td>
          </tr>
          <tr>
            <td>Video Call Time(in sec)<br />
              <span style={{fontSize:10}}>-1 represent unlimited</span>
            </td>

            <td>
              <>
                <input
                  type="number"
                  value={videoCallTime}
                  onChange={(e) => setVideoCallTime(e.target.value)}
                  placeholder="Enter video call time in sec"
                />
              </>
            </td>
          </tr>
          <tr>
            <td>plan unique name</td>
            <td>
              <>
                <input
                  value={planNameUnique}
                  onChange={(e) => setPlanNameUnique(e.target.value)}
                  placeholder="Enter unique name"
                />
              </>
            </td>
          </tr>
          <tr>
            <td>notification alert</td>
            <td
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 20,
                }}
              >
                <input
                  type="radio"
                  id="noti1"
                  name="notificationAlert"
                  checked={notificationAlert === 1}
                  value={1}
                  onClick={() => setNotificationAlert(1)}
                />
                <label htmlFor="noti1">true</label>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <input
                  type="radio"
                  id="noti0"
                  name="notificationAlert"
                  checked={notificationAlert === 0}
                  onClick={() => setNotificationAlert(0)}
                  value={0}
                />
                <label htmlFor="noti0">false</label>
              </div>
            </td>
          </tr>
          <tr>
            <td>customize qrcode</td>
            <td
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 20,
                }}
              >
                <input
                  type="radio"
                  id="customqr1"
                  name="customizeQRCode"
                  onClick={() => setCustomizeQRCode(1)}
                  checked={customizeQRCode === 1}
                  value={1}
                />
                <label htmlFor="customqr1">true</label>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <input
                  type="radio"
                  id="customqr0"
                  name="customizeQRCode"
                  checked={customizeQRCode === 0}
                  onClick={() => setCustomizeQRCode(0)}
                  value={0}
                />
                <label htmlFor="customqr0">false</label>
              </div>
            </td>
          </tr>
          <tr>
            <td>soft copy qrcode</td>
            <td
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 20,
                }}
              >
                <input
                  type="radio"
                  id="qrSoftCopy1"
                  name="qrSoftCopy"
                  onClick={() => setQrSoftCopy(1)}
                  checked={qrSoftCopy === 1}
                  value={1}
                />
                <label htmlFor="qrSoftCopy1">true</label>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <input
                  type="radio"
                  id="qrSoftCopy0"
                  name="qrSoftCopy"
                  checked={qrSoftCopy === 0}
                  onClick={() => setQrSoftCopy(0)}
                  value={0}
                />
                <label htmlFor="qrSoftCopy0">false</label>
              </div>
            </td>
          </tr>
          <tr>
            <td>cloud recording</td>
            <td
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 20,
                }}
              >
                <input
                  type="radio"
                  id="cr1"
                  name="cloudRecording"
                  onClick={() => setCloudRecording(1)}
                  value={1}
                  checked={cloudRecording === 1}
                />
                <label htmlFor="cr1">true</label>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <input
                  type="radio"
                  id="cr0"
                  name="cloudRecording"
                  value={0}
                  checked={cloudRecording === 0}
                  onClick={() => setCloudRecording(0)}
                />
                <label htmlFor="cr0">false</label>
              </div>
            </td>
          </tr>
          <tr>
            <td>call logs</td>
            <td
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 20,
                }}
              >
                <input
                  type="radio"
                  id="calllogs1"
                  name="callLogs"
                  value={1}
                  onClick={() => setCallLogs(1)}
                  checked={callLogs === 1}
                />
                <label htmlFor="calllogs1">true</label>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <input
                  type="radio"
                  id="calllogs0"
                  name="callLogs"
                  value={0}
                  checked={callLogs === 0}
                  onClick={() => setCallLogs(0)}
                />
                <label htmlFor="calllogs0">false</label>
              </div>
            </td>
          </tr>
          <tr>
            <td>snapshot capture</td>
            <td
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 20,
                }}
              >
                <input
                  type="radio"
                  id="snap1"
                  name="snapshotCapture"
                  value={1}
                  checked={snapshotCapture === 1}
                  onClick={() => setSnapshotCapture(1)}
                />
                <label htmlFor="snap1">true</label>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <input
                  type="radio"
                  id="snap0"
                  name="snapshotCapture"
                  checked={snapshotCapture === 0}
                  value={0}
                  onClick={() => setSnapshotCapture(0)}
                />
                <label htmlFor="snap0">false</label>
              </div>
            </td>
          </tr>
          <tr>
            <td>sticker support</td>
            <td
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 20,
                }}
              >
                <input
                  type="radio"
                  id="stickersupport1"
                  name="stickerSupport"
                  value={1}
                  checked={stickerSupport === 1}
                  onClick={() => setStickerSupport(1)}
                />
                <label htmlFor="stickersupport1">true</label>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <input
                  type="radio"
                  id="stickersupport0"
                  name="stickerSupport"
                  onClick={() => setStickerSupport(0)}
                  checked={stickerSupport === 0}
                  value={0}
                />
                <label htmlFor="stickersupport0">false</label>
              </div>
            </td>
          </tr>
          <tr>
            <td>vip customer care</td>
            <td
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 20,
                }}
              >
                <input
                  type="radio"
                  id="cc1"
                  name="cc"
                  value={1}
                  checked={vipCustomerCare === 1}
                  onClick={() => setVipCustomerCare(1)}
                />
                <label htmlFor="cc1">true</label>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <input
                  type="radio"
                  id="cc0"
                  name="cc"
                  value={0}
                  checked={vipCustomerCare === 0}
                  onClick={() => setVipCustomerCare(0)}
                />
                <label htmlFor="cc0">false</label>
              </div>
            </td>
          </tr>
          <tr>
            <td>two way video call</td>
            <td
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 20,
                }}
              >
                <input
                  type="radio"
                  id="twowaycall1"
                  name="twowaycall"
                  value={1}
                  checked={twoWayVideoCall === 1}
                  onClick={() => setTwoWayVideoCall(1)}
                />
                <label htmlFor="twowaycall1">true</label>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <input
                  type="radio"
                  id="twowaycall0"
                  name="twowaycall"
                  value={0}
                  checked={twoWayVideoCall === 0}
                  onClick={() => setTwoWayVideoCall(0)}
                />
                <label htmlFor="twowaycall0">false</label>
              </div>
            </td>
          </tr>
          <tr>
            <td>unlimited video call</td>
            <td
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 20,
                }}
              >
                <input
                  type="radio"
                  id="unlimitedCall1"
                  name="unlimitedCall"
                  value={1}
                  checked={unlimitedCall === 1}
                  onClick={() => setUnlimitedCall(1)}
                />
                <label htmlFor="unlimitedCal1">true</label>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <input
                  type="radio"
                  id="unlimitedCall0"
                  name="unlimitedCall"
                  value={0}
                  checked={unlimitedCall === 0}
                  onClick={() => setUnlimitedCall(0)}
                />
                <label htmlFor="unlimitedCall0">false</label>
              </div>
            </td>
          </tr>
          <tr>
            <td>Premium web</td>
            <td
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: 20,
                }}
              >
                <input
                  type="radio"
                  id="preweb1"
                  name="preweb"
                  value={1}
                  checked={premiumWeb === 1}
                  onClick={() => setPremiumWeb(1)}
                />
                <label htmlFor="preweb1">true</label>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <input
                  type="radio"
                  id="preweb0"
                  name="preweb"
                  value={0}
                  checked={premiumWeb === 0}
                  onClick={() => setPremiumWeb(0)}
                />
                <label htmlFor="preweb0">false</label>
              </div>
            </td>
          </tr>
        </table>
      )}

      {((!create && curData) || create) && (
        <Button
          style={{
            fontWeight: "600",
          }}
          onClick={handleAddEdit}
          variant="outlined"
        >
          {create ? "Add plan" : "update"}
        </Button>
      )}
    </div>
  );
};

export default Subsription;
