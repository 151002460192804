import { CircularProgress } from "@material-ui/core";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { deactivateQr, getQrDetail } from "../api";
import ConfirmationDialog from "./ConfirmationDialog";

const QrDeactivate = (props: any) => {
  const [qrshortId, setShortId] = useState("");
  const [open, setOpen] = useState(false);
  const [qrdata, setQrData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);

  const handleClickOpen = async (e: any) => {
    e.preventDefault();
    setOpenAlert(true);
  };

  const handleClose = () => {
    setOpenAlert(false);
  };

  const handleSubmit = async () => {
    if (!loading) {
      setLoading(true);
      const qrData = await getQrDetail(qrshortId);
      if (!qrData.isSuccess) {
        toast.error("Invalid QR Id");
        setLoading(false);
        setShortId("");
        handleClose();
        return;
      }
      if (qrData.isSuccess) {
        const res = await deactivateQr(qrshortId);
        if (res.isSuccess) {
          toast.success("QR Code is successfully deactivated");
          setOpen(true);
          setQrData(res.data);
        } else {
          toast.error("something is wrong");
        }
      } else {
        toast.error("This QR code is not activated");
      }
      setLoading(false);
      setShortId("");
      handleClose();
    } else {
      return;
    }
  };
  return (
    <div
      className="auth-container"
      style={{
        backgroundImage: "linear-gradient(to left, #fff,#fff)",
      }}
    >
      <form className="form" onSubmit={handleClickOpen}>
        <p className="form-title">QR Deactivate</p>
        <input
          placeholder="Enter QR ID"
          onChange={(e) => {
            setShortId(e.target.value);
          }}
          type={"text"}
          value={qrshortId}
          required
        />
        <button>
          {loading ? <CircularProgress size={17} color="inherit" /> : "Submit"}
        </button>
      </form>
      <ConfirmationDialog
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        open={openAlert}
        handleOk={handleSubmit}
        qrshortId={qrshortId}
      />
    </div>
  );
};

export default QrDeactivate;
