import { CircularProgress } from "@material-ui/core";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { addSampleOTP, getQrDetail } from "../api";
import QrDetail from "./QrDetails";

const SampleOtp = (props: any) => {
  const [number, setNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [open, setOpen] = useState(false);
  const [qrdata, setQrData] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!loading) {
      setLoading(true);
      const res = await addSampleOTP(number, otp);
      if (res.isSuccess) {
        toast.success("success");
      } else {
        toast.error("something went wrong");
      }
      setLoading(false);
      setNumber("");
      setOtp("");
    } else {
      return;
    }
  };
  return (
    <div
      className="auth-container"
      style={{
        backgroundImage: "linear-gradient(to left, #fff,#fff)",
      }}
    >
      <form className="form" onSubmit={handleSubmit}>
        <p className="form-title">Sample Otp</p>
        <input
          placeholder="Enter Phone Number"
          onChange={(e) => {
            setNumber(e.target.value);
          }}
          type={"text"}
          value={number}
          required
        />
        <p
          className="form-title"
          style={{
            fontSize: 10,
            width:'88%',
            color:"rgba(0,0,0,0.4)"
          }}
        >
          Add number with no spaces, hyphen and plus sign. Example 4434578678 ,
          911234567890
        </p>

        <input
          placeholder="Enter Sample Otp"
          onChange={(e) => {
            setOtp(e.target.value);
          }}
          type={"text"}
          value={otp}
          required
        />
        <button>
          {loading ? <CircularProgress size={17} color="inherit" /> : "Submit"}
        </button>
      </form>
    </div>
  );
};

export default SampleOtp;
