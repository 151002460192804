import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { EditAgentApi } from "../api";
import AdminAuth from "../utility/auth";
import axios from "axios";
import { uris } from "../api/baseUrl";

enum WithdrawalRequestStatus {
  Requsted = "Requested",
  Approved = "Approved",
  Failed = "Failed",
  PartiallyApproved = "PartiallyApproved",
}
const EditWithdraw = (props: any) => {
  const paymentstatusData = [
    {
      label: WithdrawalRequestStatus.Requsted,
      value: WithdrawalRequestStatus.Requsted,
    },
    {
      label: WithdrawalRequestStatus.Approved,
      value: WithdrawalRequestStatus.Approved,
    },
    {
      label: WithdrawalRequestStatus.Failed,
      value: WithdrawalRequestStatus.Failed,
    },
    {
      label: WithdrawalRequestStatus.PartiallyApproved,
      value: WithdrawalRequestStatus.PartiallyApproved,
    },
  ];
  console.log("props.vehicleData.vehicleNumber", props.referData);
  const [loading, setLoading] = useState(false);
  const [agentName, setAgentName] = useState(props.referData?.user?.name || "");
  const [paymentstatus, setPaymentstatus] = useState(props.referData.status);
  const [amount, setAmount] = useState(props.referData?.requstedAmount || 0);
  const [notes, setNotes] = useState(props.referData?.notes ?? "");

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!loading) {
      setLoading(true);
      const res = await axios.post(
        `${uris.referUrl}/withdrawals/updateWithdrawal`,
        {
          id: props.referData?._id,
          amountSent: parseFloat(amount),
          notes,
          status: paymentstatus,
        }
      );
      if (res.data?.isSuccess) {
        toast.success("success");
        AdminAuth.getInstance().eventEmitter.emit("refresh", null);
      } else {
        toast.error("something went wrong");
      }
      setLoading(false);
      setAgentName("");
    } else {
      return;
    }
  };

  // const getDepos = async () => {
  //   const depos = await getAllDepo();
  //   if (depos && depos.data) {
  //     setDepos(depos.data.depos);
  //   } else {
  //     toast.error("depot not found");
  //   }
  //   console.log("depot", depos.data);
  // };

  // useEffect(() => {
  //   getDepos();
  // }, []);

  return (
    <div
      className="auth-container"
      style={{
        backgroundImage: "linear-gradient(to left, #fff,#fff)",
        width: "100%",
      }}
    >
      <form className="form" style={{ width: "100%" }} onSubmit={handleSubmit}>
        <p className="form-title">Widthdraw request</p>

        <input
          placeholder="Enter Amount"
          onChange={(e) => {
            setAmount(e.target.value);
          }}
          type={"text"}
          value={amount}
          required
        />
        <input
          placeholder="Enter Note"
          onChange={(e) => {
            setNotes(e.target.value);
          }}
          type={"text"}
          value={notes}
        />
        <FormControl
          style={{
            width: "90%",
            fontFamily: "inherit",
            margin: "15px 0",
          }}
        >
          <InputLabel
            style={{
              fontFamily: "inherit",
            }}
            id="demo-simple-select-label"
          >
            Select payment status
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={paymentstatus}
            label="Select Depot"
            onChange={(e) => {
              setPaymentstatus(e.target.value);
            }}
            style={{
              fontFamily: "inherit",
              borderRadius: 10,
            }}
          >
            {paymentstatusData.map((status: any) => (
              <MenuItem value={status.value}>{status.label}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <button>
          {loading ? <CircularProgress size={17} color="inherit" /> : "Submit"}
        </button>
      </form>
    </div>
  );
};

export default EditWithdraw;
