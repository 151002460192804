import axios from "axios";
import React, { Component } from "react";
import toast from "react-hot-toast";
import baseUrl from "../api/baseUrl";
import "../styles/auth.css";
import AdminAuth from "../utility/auth";
import { useCookies } from "react-cookie";

interface AuthProps {
  history: any;
  cookies:any;
  setCookie:any;
}
interface AuthState {
  username: string;
  password: string;
  loading: boolean;
}

 class AuthWrap extends Component<AuthProps, AuthState> {
  AuthInstance: AdminAuth;
  constructor(props: AuthProps) {
    super(props);
    this.state = {
      username: "",
      password: "",
      loading: false,
    };
    this.AuthInstance = AdminAuth.getInstance();
  }  
  componentDidMount(): void {                    
    const loginCreds:any=this.props.cookies["loginCreds"];    
    const userDetails:any=this.props.cookies["userDetails"];        
    console.log(userDetails);
    console.log(loginCreds)
    if(loginCreds?.username && userDetails.name){      
      this.setState({username:loginCreds.username,password:loginCreds.password})
      this.AuthInstance.setAuth(true);
      this.AuthInstance.userData = userDetails;      
      this.props.history.push("dashboard");
    }    
  }
  handleLogin = async (e: any) => {
    e.preventDefault();
    this.setState({ loading: true });
    try {
      const res = await baseUrl.post("/agentlogin", {
        email: this.state.username,
        password: this.state.password,
      });
      if (res.data.isSuccess) {
        this.props.setCookie("loginCreds", {username:this.state.username,password:this.state.password}, {
          path: "/",
        });
        this.setState({ loading: true });
        this.AuthInstance.setAuth(res.data.isSuccess);
        this.AuthInstance.userData = res.data?.data;
        this.props.setCookie("userDetails", res.data?.data, {
          path: "/",
        });
        toast.success("successfully logged in as admin");
        setTimeout(() => {
          this.props.history.push("dashboard");
        }, 1000);
      }
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    return (
      <div className="auth-container">
        <form className="form" onSubmit={this.handleLogin}>
          <div className="auth-logo">
            <img src={"logo2.png"} alt="logo" />
          </div>
          <p className="form-title">Login</p>
          <input
            placeholder="Enter Email"
            onChange={(e) => {
              this.setState({ username: e.target.value });
            }}
            type={"text"}
            value={this.state.username}
            required
          />
          <input
            placeholder="Enter Password"
            onChange={(e) => {
              this.setState({ password: e.target.value });
            }}
            type={"password"}
            value={this.state.password}
            required
          />
          <button>Login</button>
        </form>
      </div>
    );
  }
}



const Auth = ({history}:any) => {
  const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);
  return (
    <AuthWrap history={history} cookies={cookies} setCookie={setCookie}/>
  )
}

export default Auth
