import React, { useEffect, useState } from "react";
import { IoReload, IoSearchSharp } from "react-icons/io5";
import { FiFilter } from "react-icons/fi";
import { FormControl, Select, InputLabel } from "@material-ui/core";
import DatePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";
import { Button } from "@mui/material";
import CsvDownload from "react-csv-downloader";
import axios from "axios";
import { uris } from "../../api/baseUrl";

const WithdrawalRequestStatus = {
  All: "all",
  Requsted: "Requested",
  Approved: "Approved",
  Failed: "Failed",
  PartiallyApproved: "PartiallyApproved",
};
const TableHeaderAction = ({
  filter,
  setGlobalFilter,
  setFilter,
  setDateRangeFilter,
  paymentstatus,
  setPaymentstatus,
}) => {
  const columns = [
    {
      id: "name",
      displayName: "Name",
    },
    {
      id: "phoneNumber/email",
      displayName: "phoneNumber/email",
    },
    {
      id: "AccountNo",
      displayName: "AccountNo",
    },
    {
      id: "AccountType",
      displayName: "AccountType",
    },
    {
      id: "bankName",
      displayName: "bankName",
    },
    {
      id: "paypalId",
      displayName: "paypalId",
    },
    {
      id: "status",
      displayName: "status",
    },
    {
      id: "swiftOrBICNumber",
      displayName: "swiftOrBICNumber",
    },
    {
      id: "totalAmountEarned",
      displayName: "totalAmountEarned",
    },
    {
      id: "totalAmountWithdrawn",
      displayName: "totalAmountWithdrawn",
    },
    {
      id: "createdAt",
      displayName: "createdAt",
    },
  ];

  const paymentstatusData = [
    {
      label: WithdrawalRequestStatus.All,
      value: WithdrawalRequestStatus.All,
    },
    {
      label: WithdrawalRequestStatus.Requsted,
      value: WithdrawalRequestStatus.Requsted,
    },
    {
      label: WithdrawalRequestStatus.Approved,
      value: WithdrawalRequestStatus.Approved,
    },
    {
      label: WithdrawalRequestStatus.Failed,
      value: WithdrawalRequestStatus.Failed,
    },
    {
      label: WithdrawalRequestStatus.PartiallyApproved,
      value: WithdrawalRequestStatus.PartiallyApproved,
    },
  ];
  const [dateRange, setDateRange] = useState(null);
  const onDownload = async () => {
    /* global BigInt */
    const data_to_download = [];
    const res = await axios.get(
      `${uris.referUrl}/withdrawals/allWithdrawalRequestsWithStatus/all`
    );
    if (res.data && res.data.data) {
      const csvData = res.data.data;
      console.log("csvData", csvData);
      csvData.forEach((data) => {
        // console.log("isActivated", isActivated);
        data.user &&
          data_to_download.push({
            name: data.user.name,
            "phoneNumber/email": data.user.email ?? BigInt(data.user.phoneNo),
            AccountNo: String(data?.account.accountNo),
            AccountType: String(data?.account.accountType),
            bankName: data?.account.bankName,
            paypalId: String(data?.account.paypalId ?? ""),
            status: String(data?.status ?? ""),
            swiftOrBICNumber: String(data?.account.swiftOrBICNumber ?? ""),
            totalAmountEarned: String(data?.user.totalAmountEarned),
            totalAmountWithdrawn: String(data?.user.totalAmountWithdrawn),
            createdAt: data.createdAt,
          });
      });
    }
    console.log("data", data_to_download);
    return Promise.resolve(data_to_download);
  };
  useEffect(() => {
    console.log("redux", dateRange);
    if (!dateRange) {
      setDateRangeFilter(null);
      setFilter("createdAt", null);
    } else {
      setDateRangeFilter(dateRange);
    }
  }, [dateRange]);
  return (
    <>
      <div className="table__header-action">
        <form>
          <div className="table__form-box">
            <IoSearchSharp size={22} color="#000" />
            <input
              value={filter || ""}
              onChange={(e) => setGlobalFilter(e.target.value)}
              type="text"
              placeholder="Search by orderid,status.."
            />
          </div>
        </form>
        <FormControl variant="filled">
          <InputLabel htmlFor="orderStatus">payment status</InputLabel>
          <Select
            native
            onChange={(e) => setPaymentstatus(e.target.value)}
            value={paymentstatus}
          >
            {paymentstatusData.map((option) => {
              return <option value={option.value}>{option.label}</option>;
            })}
          </Select>
        </FormControl>
        <CsvDownload
          columns={columns}
          datas={onDownload}
          filename="userdata"
          extension=".csv"
          // separator=";"
        >
          <Button
            style={{
              fontWeight: "600",
            }}
            variant="contained"
          >
            {/* <CSVLink
                filename="doorvi_master_data.csv"
                asyncOnClick={true}
                data={this.state.csvDataState}
                onClick={this.onDownload}
                ref={this.csvInstance}
                style={{ textDecoration: "none", color: "#000" }}
              >
              </CSVLink> */}
            Export to csv
          </Button>
        </CsvDownload>
      </div>
    </>
  );
};

export default TableHeaderAction;
