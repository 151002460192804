import React, { Component } from "react";
import { QRCodeSVG } from "qrcode.react";
import { ReactComponent as DoorViLogo } from "../../assets/img/doorvi.svg";
import { ReactComponent as BorderBottom } from "../../assets/img/border_bottom.svg";
import { ReactComponent as Ring } from "../../assets/img/call.svg";
import gardenia from "../../assets/img/Gardenia.png";
import { useTranslation } from "react-i18next";
import { getCountryCallingCode } from "libphonenumber-js";
interface QRGenratorProps {
  value: string;
  index: number;
  qr: any;
  qrType: string;
}

function GardeniaQRGenrator(props: any) {
  const { t } = useTranslation();
  return (
    <>
      <div className="qr-wrap">
        <p className="qr-id">
          {props.countries[0] === "all"
            ? `No 0-${props.qr.shortId}`
            : `No ${getCountryCallingCode(props.countries[0])}-${
                props.countries.length
              }${props.qr.shortId}`}
        </p>
        <p className="qr-val">
          <Ring
            style={{
              width: 22,
              height: 20,
            }}
          />
        </p>

        <QRCodeSVG
          size={182}
          style={{
            margin: "5px 0",
          }}
          className="qrcode"
          value={props.value}
        />
        <div className="qr-footer">
          <p>{t("Scan_QR_Code")}</p>
          <p>{t("To_Contact_inside")}</p>
          <BorderBottom
            style={{
              marginTop: 3,
            }}
          />
        </div>
        <ul className="qr-notes">
          <li>{t("Please_Do_Not_Knock_or_Ring_The_Bell")}</li>
          <li>{t("Scan_Camera")}</li>
        </ul>
        {/* <div className="footer-logo_wrap"> */}
        {/* <DoorViLogo className="footer-logo" /> */}
        <img src={gardenia} style={{ width: 130, height: 30 }} />
      </div>
    </>
  );
}

export default GardeniaQRGenrator;
