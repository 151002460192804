export const setDateRange = (dateRange) => {
  return {
    type: "SET_DATE_RANGE",
    payload: dateRange,
  };
};
export const setStatusFilterValue = (status) => {
  return {
    type: "SET_STATUS_FILTERVALUE",
    payload: status,
  };
};
