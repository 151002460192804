import React, { useEffect, useState } from "react";
import { IoReload, IoSearchSharp } from "react-icons/io5";
import { FiFilter } from "react-icons/fi";
import { FormControl, Select, InputLabel } from "@material-ui/core";
import DatePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";

const TableHeaderAction = ({
  filter,
  setGlobalFilter,
  setFilter,
  setDateRangeFilter,
  setStatusFilterValue,
  fetchOrdersData,
}) => {
  const [dateRange, setDateRange] = useState(null);
  useEffect(() => {
    console.log("redux", dateRange);
    if (!dateRange) {
      setDateRangeFilter(null);
      setFilter("createdAt", null);
    } else {
      setDateRangeFilter(dateRange);
    }
  }, [dateRange]);
  return (
    <>
      <div className="table__header-action">
        <form>
          <div className="table__form-box">
            <IoSearchSharp size={22} color="#000" />
            <input
              value={filter || ""}
              onChange={(e) => setGlobalFilter(e.target.value)}
              type="text"
              placeholder="Search by name,status.."
            />
          </div>
        </form>
        <div className="table__date-range">
          <DatePicker
            value={dateRange}
            onChange={(newDate) => setDateRange(newDate)}
          />
          <FormControl variant="filled">
            <InputLabel htmlFor="paymentStatus">payment status</InputLabel>
            <Select
              native
              onChange={(e) => setFilter("paymentStatus", e.target.value)}
              inputProps={{
                id: "paymentStatus",
              }}
            >
              <option value="" aria-label="None" />
              <option value="Approved">PaymentApproved</option>
              <option value="InPending">PaymentPending</option>
            </Select>
          </FormControl>
        </div>
        <div className="table__header-icons">
          <IoReload onClick={fetchOrdersData} size={25} cursor="pointer" />
        </div>
      </div>
    </>
  );
};

export default TableHeaderAction;
