import {
  MeetingHandler,
  MeetingStartRequest,
  MeetingType,
  MessagePayload,
  VaniEvent,
} from "vani-meeting-client";

import EventEmitter from "events";

export enum LocalEvents {
  OnRefershDevices = "refershDevices",
  OnMessageListUpdated = "onMessageListUpdated",
}

class WebrtcCallHandler {
  static instance: WebrtcCallHandler | undefined = new WebrtcCallHandler();
  meetingHandler: MeetingHandler = new MeetingHandler();
  meetingRequest?: MeetingStartRequest;
  eventEmitter: any;
  customEventEmitter: any;
  isAdmin: boolean;
  roomId: null;
  messages: MessagePayload[];
  videoAudioRequestPayload?: any;
  static getInstance() {
    if (WebrtcCallHandler.instance === undefined) {
      WebrtcCallHandler.instance = new WebrtcCallHandler();
    }
    return WebrtcCallHandler.instance;
  }

  constructor() {
    this.meetingRequest = undefined;
    this.eventEmitter = new EventEmitter();
    this.customEventEmitter = new EventEmitter();
    this.isAdmin = false;
    this.roomId = null;
    this.messages = [];
    this.getMeetingRequest = this.getMeetingRequest.bind(this);
    this.cleanUp = this.cleanUp.bind(this);
  }
  cleanUp(shouldRedirect: boolean) {
    console.log("Vani CLeanup");
    this.getMeetingHandler().endAndDestory();
    this.meetingRequest = undefined;
    this.messages = [];
    this.eventEmitter.removeAllListeners();

    WebrtcCallHandler.instance = undefined;
  }
  setup(roomId: string, userId: string, wssUrl?: string) {
    if (this.meetingRequest == null) {
      this.meetingRequest = this.getMeetingHandler().meetingStartRequestObject(
        roomId,
        userId,
        process.env.REACT_APP_ID as string,
        wssUrl ? wssUrl : (process.env.REACT_APP_WSS_URL as string)
      );
      this.meetingRequest.numberOfUsers = 10;      
      this.meetingRequest.isAdmin = this.isAdmin;
      // this.meetingRequest.videoCaptureHeight =  window.innerHeight
      // this.meetingRequest.videoCaptureWidth = window.innerWidth
      this.meetingRequest.meetingType = MeetingType.SFU;
      this.meetingRequest.simulcastEncodings = [];

      console.log(this.meetingRequest, window.innerHeight);
      this.getMeetingHandler().getEventEmitter()?.on(VaniEvent.OnNewChatMessageReceived, this.onNewChatMessage)
    }
  }

  onNewChatMessage = async (message: MessagePayload) => {
    if(message.type === "AppEvent"){
      console.log("AppEvent=",message.extraData)
    }

  }
  getMeetingHandler() {
    return this.meetingHandler;
  }

  getMeetingRequest() {
    return this.meetingRequest;
  }
}

export default WebrtcCallHandler;
