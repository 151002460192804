import moment from "moment";
export const COLUMNS = [
  {
    Header: "name",
    accessor: "name",
    Cell: ({ value }) => {
      return value?value.substring(0, 12):"Vani User";
    },
    Filter: () => {
      return null;
    },
  },
  {
    Header: "phoneNumber",
    accessor: "phoneNumber/email",
    Cell: ({ row }) => {
      return row.original.phoneNumber
        ? row.original.phoneNumber?.substring(0, 50)
        : row.original.email?row.original.email:"No Details";
    },
    Filter: () => {
      return null;
    },
  },
  {
    Header: "ReferCode",
    accessor: "referalCode",
    Filter: () => {
      return null;
    },
  },
  {
    Header: "Friend Joined",
    accessor: "totalReference",
    Filter: () => {
      return null;
    },
  },
  {
    Header: "Total Amount Earned",
    Cell: ({ row }) => {
      return row.original.totalAmountEarned;
    },
  },
  {
    Header: "Cur balance",
    Cell: ({ row }) => {
      return (row.original.totalAmountEarned - row.original.totalAmountWithdrawn).toFixed(2);
    },
  },
  {
    Header: "createdAt",
    accessor: "joinTimeStamp",
    Cell: ({ value }) => {
      return moment(value).format("DD-MM-YYYY hh:mm A");
    },
  },
];
